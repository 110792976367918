import { useEffect } from 'react'
import { Card, Col, Divider, List, Row, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  getPosts,
  nextPostsPage,
  selectPostById,
  selectPostQuery,
} from '../../reducers/PostReducer'
import Post from './Post'
import { useAppDispatch } from '../../reducers/hooks'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store'
import PostModal from './PostModal'
import { getAuthors } from '../../reducers/AuthorReducer'
import { useIntl } from 'react-intl'

const PostList = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const postQuery = useSelector(selectPostQuery())

  const { posts, selectedPostId, totalPosts, showCreateModal, showEditModal } =
    useSelector((state: RootState) => state.posts)

  const selectedPost = useSelector(selectPostById(selectedPostId ?? 0))

  useEffect(() => {
    dispatch(getAuthors())
  }, [dispatch])

  useEffect(() => {
    if (totalPosts === 0) {
      return
    }
    dispatch(getPosts(postQuery))
  }, [dispatch, postQuery])

  useEffect(() => {
    if (postQuery.page !== 1 || totalPosts !== 0) {
      return
    }
    // Dispatch only if there is no post
    // Used when reloading the posts list and having downloaded previsouly only 1 page of post
    dispatch(getPosts(postQuery))
  }, [dispatch, postQuery, totalPosts])

  return (
    <>
      <PostModal action="create" show={showCreateModal} />
      <PostModal action="update" show={showEditModal} post={selectedPost} />
      <InfiniteScroll
        dataLength={posts.length}
        next={() => dispatch(nextPostsPage())}
        hasMore={posts.length < totalPosts}
        loader={<FeedLoader />}
        endMessage={
          <Divider plain>{intl.formatMessage({ id: 'POST.LIST_END' })}</Divider>
        }
        scrollableTarget="scrollableDiv"
        style={{ padding: '0px' }}
      >
        <Row style={{ maxWidth: 900, margin: 'auto' }}>
          <Col md={24}>
            <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={posts}
              renderItem={(item) => (
                <List.Item style={{ padding: 0 }}>
                  <Post post={item} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </InfiniteScroll>
    </>
  )
}

export default PostList

const FeedLoader = () => {
  return (
    <Row style={{ maxWidth: 900, margin: 'auto' }}>
      <Col md={24}>
        <List
          grid={{ gutter: 16, column: 1 }}
          dataSource={['']}
          renderItem={(item) => (
            <List.Item style={{ padding: 0 }}>
              <Card>
                <Skeleton.Image active />
                <Skeleton paragraph={{ rows: 5 }} active />
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  )
}
