import { Alert, Button, List, Modal, Space } from 'antd'
import Create from './form/Create'
import { useSelector } from 'react-redux'
import AuthorCell from './AdminCell'
import { RootState } from '../../reducers/store'
import { useState } from 'react'
import Loading from '../utils/Loading'
import { useIntl } from 'react-intl'
import AdminCell from './AdminCell'
import { selectAdmins } from '../../reducers/UserReducer'

const AdminManager = () => {
  const intl = useIntl()

  const [action, setAction] = useState<'create' | 'view'>('view')

  const { isLoading, error } = useSelector((state: RootState) => state.user)

  const admins = useSelector(selectAdmins())

  return (
    <>
      {action == 'view' && (
        <>
          <List
            itemLayout="vertical"
            dataSource={admins}
            bordered={false}
            style={{ padding: 0, margin: 0 }}
            renderItem={(admin) => <AdminCell admin={admin} />}
          />
          <Space direction="horizontal">
            <Button onClick={() => setAction('create')}>
              {intl.formatMessage({ id: 'ADMIN.ADD_ADMIN_BUTTON' })}
            </Button>
            <Space></Space>
            {isLoading && <Loading></Loading>}
            {error && (
              <Alert
                message={intl.formatMessage({ id: error as string })}
                type="error"
              />
            )}
          </Space>
        </>
      )}

      {action == 'create' && (
        <Create
          onFinish={() => {
            setAction('view')
          }}
        />
      )}
    </>
  )
}

export default AdminManager
