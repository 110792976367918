export const API_URL = process.env.REACT_APP_API_URL

export const AUTH_URL = API_URL + '/auth'
export const LOGIN_URL = AUTH_URL + '/login'
export const ME_INFOS_URL = AUTH_URL + '/me'

export const AUTHORS_URL = API_URL + '/authors'
export const POSTS_URL = API_URL + '/posts'
export const QUICK_LINKS_URL = API_URL + '/quick-links'
export const TAGS_URL = API_URL + '/tags'
export const USERS_URL = API_URL + '/users'
export const ADMINS_URL = API_URL + '/admins'

export const CAROUSEL_URL = API_URL + '/carousel'
export const CAROUSEL_SLIDE_URL = CAROUSEL_URL + '/slides'
