import { Space, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { Post as PostModel } from '../../../models/Post'
import AuthorAvatar from '../../author/AuthorAvatar'
import PostHeader from '../PostHeader'
const { Title, Paragraph } = Typography

interface Props {
  post: PostModel
}

const Quote = ({ post }: Props) => {
  const intl = useIntl()
  const markup = { __html: post.content }

  return (
    <>
      <PostHeader displayAuthor={false} displayTags={false} post={post} />
      <Space direction="horizontal" size={25}>
        <AuthorAvatar authorId={post.author} />
        <Space direction="vertical">
          <Title level={4}>{post.title}</Title>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            <div className="html-render" dangerouslySetInnerHTML={markup} />
          </Paragraph>
        </Space>
      </Space>
    </>
  )
}

export default Quote
