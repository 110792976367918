import { Card, Carousel, Empty } from 'antd'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  getCarousel,
  selectConfig,
  selectSlides,
} from '../../reducers/CarouselReducer'
import { useAppDispatch } from '../../reducers/hooks'
import Slide from './Slide'

const CarouselWrapper = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const config = useSelector(selectConfig)
  const slides = useSelector(selectSlides)

  useEffect(() => {
    dispatch(getCarousel())
  }, [dispatch])

  return (
    <>
      {!config.hide ? (
        slides && slides.length >= 2 ? (
          <Carousel
            autoplay={config.autoplay}
            autoplaySpeed={config.duration}
            style={{ marginBottom: 20, borderRadius: 10, overflow: 'hidden' }}
          >
            {slides.map((slide) => (
              <Slide key={slide.id} slide={slide} />
            ))}
          </Carousel>
        ) : (
          <Card style={{ marginBottom: 20 }}>
            <Empty
              description={intl.formatMessage({
                id: 'CAROUSEL.WELCOME_MESSAGE',
              })}
            ></Empty>
          </Card>
        )
      ) : (
        <Card style={{ marginBottom: 20 }}>
          <Empty
            description={intl.formatMessage({
              id: 'CAROUSEL.HIDDEN_MESSAGE',
            })}
          ></Empty>
        </Card>
      )}
    </>
  )
}

export default CarouselWrapper
