import { configureStore } from '@reduxjs/toolkit'
import AuthorReducer from './AuthorReducer'
import CarouselReducer from './CarouselReducer'
import PostReducer from './PostReducer'
import QuickLinksReducer from './QuickLinksReducer'
import TagReducer from './TagReducer'
import UserReducer from './UserReducer'

export const store = configureStore({
  reducer: {
    quickLink: QuickLinksReducer,
    posts: PostReducer,
    authors: AuthorReducer,
    tag: TagReducer,
    user: UserReducer,
    carousel: CarouselReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
