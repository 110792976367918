import axios from 'axios'
import { Image } from 'antd'
import { Buffer } from 'buffer'
import { useEffect, useState } from 'react'
import { Picture } from '../../models/Picture'

type Props = {
  picture: Picture
  style?: React.CSSProperties
}

const ProtectedImage = ({
  picture,
  style = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: '#000',
  },
}: Props) => {
  const [image, setImage] = useState<string | undefined>(undefined)

  useEffect(() => {
    axios
      .get(picture.src, { responseType: 'arraybuffer' })
      .then((response) =>
        setImage(Buffer.from(response.data, 'binary').toString('base64'))
      )
  }, [picture.src])

  return image ? (
    <Image
      loading="eager"
      src={`data:;base64,${image}`}
      alt={picture.name}
      style={style}
    ></Image>
  ) : (
    <></>
  )
}

export default ProtectedImage
