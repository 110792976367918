import React, { useState } from 'react'
import { Layout, theme } from 'antd'
import LinkList from '../quicklink/LinkListContainer'

const { Sider } = Layout

export const siderWidth = 300
export const siderCollapsedWidth = 80

interface Props {
  onCollapse: () => void
  collapsed: boolean
}

export const LayoutSider = ({ collapsed, onCollapse }: Props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => onCollapse()}
      breakpoint="lg"
      collapsedWidth={siderCollapsedWidth}
      width={siderWidth}
      style={{
        background: colorBgContainer,
        overflow: 'scroll',
        height: '100vh',
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        width: siderWidth,
        paddingTop: 64,
        marginBottom: 200,
      }}
      trigger={null}
    >
      <LinkList isCollapsed={collapsed} />
    </Sider>
  )
}
