import axios from 'axios'
import { Buffer } from 'buffer'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Picture } from '../../models/Picture'
import Restricted from '../restricted/Restricted'
import { EllipsisOutlined } from '@ant-design/icons'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { QuickLink as QuickLinkModel } from '../../models/QuickLink'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  Avatar,
  Button,
  Dropdown,
  List,
  MenuProps,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import {
  deleteQuickLink,
  getQuickLinks,
  selectQuickLinkById,
  selectQuickLinkId,
} from '../../reducers/QuickLinksReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: QuickLinkModel
  displayMode: 'small' | 'large'
  upArrow?: () => void
  downArrow?: () => void
}

const QuickLink = ({ id, model, displayMode, upArrow, downArrow }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const quickLink = model ? model : useAppSelector(selectQuickLinkById(id!))

  const [base64ImageIsLoading, setBase64ImageIsLoading] =
    useState<boolean>(false)
  const [base64Image, setBase64Image] = useState<string | undefined>(undefined)
  const [domain, setDomain] = useState<string | undefined>(undefined)
  const [items, setItems] = useState<MenuProps['items']>([
    {
      key: '1',
      label: (
        <Text onClick={() => dispatch(selectQuickLinkId(quickLink!.id))}>
          {intl.formatMessage({ id: 'UPDATE' })}
        </Text>
      ),
    },
    {
      key: '2',
      label: (
        <Text
          onClick={() =>
            dispatch(deleteQuickLink(quickLink!)).then(() =>
              dispatch(getQuickLinks())
            )
          }
        >
          {intl.formatMessage({ id: 'DELETE' })}
        </Text>
      ),
    },
  ])

  useEffect(() => {
    if (!quickLink) {
      return
    }

    const url = new URL(quickLink.url)
    setDomain(url.hostname.replace('www.', ''))

    if (quickLink.image) {
      setBase64ImageIsLoading(true)
      axios
        .get((quickLink.image as Picture).src, { responseType: 'arraybuffer' })
        .then((response) => {
          setBase64Image(
            Buffer.from(response.data, 'binary').toString('base64')
          )
          setBase64ImageIsLoading(false)
        })
    }
  }, [quickLink])

  return quickLink ? (
    <>
      {displayMode === 'large' && (
        <List.Item style={{ border: 0, padding: '8px 0' }}>
          <Space
            style={{
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Link to={quickLink.url} target="_blank" rel="noopener noreferrer">
              <Space direction="horizontal" style={{ cursor: 'pointer' }}>
                {!base64ImageIsLoading && (
                  <Avatar
                    shape="square"
                    size={50}
                    src={
                      (quickLink.image as Picture)
                        ? `data:;base64,${base64Image}`
                        : quickLink.iconUrl
                    }
                  />
                )}
                {base64ImageIsLoading && (
                  <Skeleton.Avatar size={50} active={true} shape="square" />
                )}
                <Space direction="vertical" size={0}>
                  <Text>{quickLink.name}</Text>
                  <Text type="secondary">{domain}</Text>
                </Space>
              </Space>
            </Link>

            <Restricted type="admin">
              <Space>
                {upArrow && <UpOutlined onClick={upArrow} />}
                {downArrow && <DownOutlined onClick={downArrow} />}
                {!upArrow && !downArrow && (
                  <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                    <Button type="text">
                      <EllipsisOutlined />
                    </Button>
                  </Dropdown>
                )}
              </Space>
            </Restricted>
          </Space>
        </List.Item>
      )}
      {displayMode === 'small' && (
        <List.Item style={{ border: 0, padding: '0', marginBottom: 10 }}>
          <Space
            direction="vertical"
            onClick={() => window.open(quickLink.url, '_blank')}
            style={{ textAlign: 'center', width: '100%' }}
            size={2}
          >
            <Avatar shape="square" size={50} src={quickLink.iconUrl} />

            <Text>{quickLink.name}</Text>
          </Space>
        </List.Item>
      )}
    </>
  ) : (
    <></>
  )
}

export default QuickLink
