import { useEffect, useState } from 'react'
import { Button } from 'antd'
import QuikLinkModal from './QuikLinkModal'
import {
  getQuickLinks,
  onOpenCreateModal,
  reorderQuickLinks,
  selectAllQuickLinks,
  selectSelectedQuickLink,
} from '../../reducers/QuickLinksReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useSelector } from 'react-redux'
import Restricted from '../restricted/Restricted'
import LinkListSkeleton from './components/LinkListSkeleton'
import { useIntl } from 'react-intl'
import LinkListCollapsed from './components/LinkListCollapsed'
import LinkListByTags from './components/LinkListByTags'
import { selectTagsWithQuickLinks } from '../../reducers/TagReducer'

interface Props {
  isCollapsed: Boolean
}

const LinkListContainer = ({ isCollapsed }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [displayReorderArrows, setDisplayReorderArrows] =
    useState<boolean>(false)

  const { showCreateModal, showEditModal, isLoading } = useAppSelector(
    (state) => state.quickLink
  )

  const tags = useSelector(selectTagsWithQuickLinks())
  const quickLinks = useSelector(selectAllQuickLinks())

  useEffect(() => {
    dispatch(getQuickLinks())
  }, [dispatch])

  return (
    <>
      {isLoading && tags.length === 0 ? (
        <LinkListSkeleton isCollapsed={isCollapsed} />
      ) : (
        <>
          {!isCollapsed ? (
            tags.length === 0 ? (
              <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>
                <Button
                  style={{ width: '100%', textAlign: 'center' }}
                  onClick={() => dispatch(onOpenCreateModal())}
                >
                  {intl.formatMessage({ id: 'QUICKLINK.ADD_QUICKLINK_BUTTON' })}
                </Button>
              </div>
            ) : (
              <>
                <LinkListByTags
                  tagIds={tags.map((t) => t.id)}
                  displayReorderArrows={displayReorderArrows}
                />
                <Restricted type="admin">
                  <div
                    style={{ width: '100%', textAlign: 'center', padding: 10 }}
                  >
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      style={{ width: '100%', textAlign: 'center' }}
                      onClick={() => {
                        setDisplayReorderArrows(!displayReorderArrows)
                        if (!displayReorderArrows === false) {
                          // Time to save the new order

                          const tagsNewOrder = tags
                            .sort(
                              (a, b) =>
                                (a.quickLinkOrder ?? 0) -
                                (b.quickLinkOrder ?? 0)
                            )
                            .map((tag) => {
                              return {
                                id: tag.id,
                                quickLinkOrder: tag.quickLinkOrder,
                                quickLinks: quickLinks
                                  .map((ql) => ({
                                    id: ql.id,
                                    sourceId: ql.sourceId,
                                    order: ql.order,
                                  }))
                                  .filter((ql) =>
                                    tag.quickLinks?.includes(ql.id)
                                  )
                                  .sort(
                                    (a, b) => (a.order ?? 0) - (b.order ?? 0)
                                  ),
                              }
                            })
                          dispatch(reorderQuickLinks(tagsNewOrder))
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: displayReorderArrows
                          ? 'CLOSE_REORDER_BUTTON'
                          : 'REORDER_BUTTON',
                      })}
                    </Button>
                  </div>
                </Restricted>
              </>
            )
          ) : (
            <LinkListCollapsed />
          )}
        </>
      )}
      <QuikLinkModal show={showCreateModal} action="create" />
      <QuikLinkModal show={showEditModal} action="update" />
    </>
  )
}

export default LinkListContainer
