import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Input, Select, Space, Upload, UploadFile } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'
import { SlideModel } from '../../../models/Slide'
import {
  selectSelectedSlide,
  updateSlide,
} from '../../../reducers/CarouselReducer'
import TextArea from 'antd/es/input/TextArea'
import { useIntl } from 'react-intl'
import ImgCrop from 'antd-img-crop'
import { handleCompressedUpload } from '../../../utils/ImageCompressor'
import { PlusOutlined } from '@ant-design/icons'
import { requiredRule, stringRule, urlRule } from '../../../utils/rules'

interface Props {
  onFinish: () => void
}

const UpdateSlide = ({ onFinish }: Props) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const [form] = Form.useForm()

  const selectedSlide = useSelector(selectSelectedSlide())
  const [file, setFile] = useState<UploadFile | undefined>(undefined)

  const [formData, setFormData] = useState<SlideModel | undefined>(undefined)

  const onFormSubmit = () => {
    if (formData === undefined) {
      return
    }

    dispatch(updateSlide({ ...formData, picture: file }))
    form.resetFields()
    onFinish()
  }

  useEffect(() => {
    if (!selectedSlide) {
      return
    }
    // Mapping the selectedQuickLink's tags allow to fill the Select tags properly

    setFormData(selectedSlide)
    form.setFieldsValue(selectedSlide)
  }, [form, selectedSlide])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allFields) => {
          setFormData({ ...formData, ...allFields })
        }}
        initialValues={selectedSlide}
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="title"
          label={intl.formatMessage({ id: 'TITLE' })}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="body"
          label={intl.formatMessage({ id: 'BODY' })}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="picture"
          label={intl.formatMessage({ id: 'FIELD_LABEL.FILES_OPTIONAL' })}
        >
          <ImgCrop rotationSlider cropShape="rect" aspect={16 / 9}>
            <Upload
              maxCount={1}
              accept="image/*"
              listType="picture-card"
              beforeUpload={(file) => {
                handleCompressedUpload(file, (compressedFile) =>
                  setFile(compressedFile)
                )
                return false
              }}
              onRemove={() => {
                setFile(undefined)
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>
                  {intl.formatMessage({ id: 'SELECT_FILES' })}
                </div>
              </div>
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          name="link"
          label={intl.formatMessage({ id: 'LINK' })}
          rules={[requiredRule(intl), urlRule(intl)]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({ id: 'SUBMIT' })}
            </Button>
            <Button type="default" htmlType="reset" onClick={() => onFinish()}>
              {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default UpdateSlide
