import { Modal } from 'antd'
import Create from './form/Create'
import Update from './form/Update'
import { useAppDispatch } from '../../reducers/hooks'
import {
  getQuickLinks,
  onCloseCreateModal,
  onCloseUpdateModal,
} from '../../reducers/QuickLinksReducer'

interface Props {
  show?: boolean
  action: 'create' | 'update'
}

const QuikLinkModal = ({ show, action }: Props) => {
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(onCloseCreateModal())
    dispatch(onCloseUpdateModal())
    dispatch(getQuickLinks())
  }

  return (
    <>
      <Modal open={show} onCancel={handleClose} footer={[]}>
        {action === 'create' && <Create onFinish={handleClose} />}
        {action === 'update' && <Update onFinish={handleClose} />}
      </Modal>
    </>
  )
}

export default QuikLinkModal
