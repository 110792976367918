import React from 'react'
import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import App from '../App'
import Profile from '../pages/Profile'
import { PageLayout } from '../components/layout/PageLayout'
import { AuthLayout } from '../components/layout/auth/AuthLayout'
import { useIsAuthenticated } from '@azure/msal-react'
import { Login } from '../pages/Login'
import LoginRedirect from '../pages/LoginRedirect'
import Home from '../pages/Home'

const AppRoutes: FC = () => {
  const isAuthenticated = useIsAuthenticated()

  return (
    <Routes>
      <Route element={<App />}>
        {/* MSAL Login redirect route */}
        <Route element={<AuthLayout />}>
          <Route path="/login-redirect" element={<LoginRedirect />} />
        </Route>

        {isAuthenticated && (
          <Route element={<PageLayout />}>
            <Route path="/" element={<Home />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        )}

        {!isAuthenticated && (
          <Route element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route index element={<Navigate to="login" />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
        )}
      </Route>
    </Routes>
  )
}

export { AppRoutes }
