import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Col, Image, Row, Segmented, Space, Typography } from 'antd'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import LangSelector from '../components/lang-selector/LangSelector'
const { Title, Text } = Typography

export function Login() {
  const { instance } = useMsal()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleLogin = (loginType: string) => {
    setAnchorEl(null)

    if (loginType === 'popup') {
      instance.loginPopup(loginRequest)
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest)
    }
  }
  return (
    <Space
      direction="vertical"
      style={{ padding: 20, textAlign: 'center' }}
      size={20}
    >
      <img src="assets/linear_logo.png" width={300} />
      <Title style={{ margin: 0, marginBottom: '1rem' }}>
        {intl.formatMessage({ id: 'LOGIN.WELCOME_MESSAGE_TITLE' })}
      </Title>
      <Button
        style={{ background: '#293348', color: '#fff' }}
        size="large"
        onClick={() => handleLogin('redirect')}
      >
        {intl.formatMessage({ id: 'LOGIN.BUTTON_TEXT' })}
      </Button>
      <LangSelector />
    </Space>
  )
}
