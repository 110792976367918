import { Avatar, Button, Dropdown, List, Space, Typography } from 'antd'
import { MenuProps } from 'rc-menu'
import { useAppDispatch } from '../../reducers/hooks'
import { EllipsisOutlined } from '@ant-design/icons'
import { SlideModel } from '../../models/Slide'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { Buffer } from 'buffer'

import {
  deleteSlide,
  downSlide,
  selectSlideId,
  upSlide,
} from '../../reducers/CarouselReducer'
import { useEffect, useState } from 'react'
import axios from 'axios'

const Text = Typography

interface Props {
  slide: SlideModel
  updateTapped: () => void
  canUp?: boolean
  canDown?: boolean
}

const SlideCell = ({ slide, updateTapped, canUp, canDown }: Props) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const [image, setImage] = useState<string | undefined>(undefined)

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Text>{intl.formatMessage({ id: 'UPDATE' })}</Text>,
      onClick: () => (dispatch(selectSlideId(slide.id)), updateTapped()),
    },
    {
      key: '2',
      label: <Text>{intl.formatMessage({ id: 'DELETE' })}</Text>,
      onClick: () => dispatch(deleteSlide(slide)),
    },
  ]

  useEffect(() => {
    axios
      .get(slide.pictureUrl, { responseType: 'arraybuffer' })
      .then((response) =>
        setImage(Buffer.from(response.data, 'binary').toString('base64'))
      )
  }, [slide.pictureUrl])

  return (
    <List.Item style={{ border: 0, padding: '8px 0' }}>
      <Space
        style={{
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Space direction="horizontal">
          {slide.pictureUrl && (
            <Avatar shape="circle" size={60} src={`data:;base64,${image}`} />
          )}
          <Space direction="vertical" size={0}>
            <Text>{`${slide.title}`}</Text>
          </Space>
        </Space>
        <Space direction="horizontal">
          {canUp && (
            <Button onClick={() => dispatch(upSlide(slide))}>
              <UpOutlined />
            </Button>
          )}
          {canDown && (
            <Button onClick={() => dispatch(downSlide(slide))}>
              <DownOutlined />
            </Button>
          )}
          <Dropdown menu={{ items }} placement="bottomLeft" arrow>
            <Button type="text">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </Space>
      </Space>
    </List.Item>
  )
}

export default SlideCell
