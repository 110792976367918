import moment from 'moment'
import { Avatar, Space, Tag as TagANTD, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { Post } from '../../models/Post'
import PostToolbar from './PostToolbar'
import Tag from '../tag/Tag'
import { selectAuthorById } from '../../reducers/AuthorReducer'
import Restricted from '../restricted/Restricted'

const { Text } = Typography

interface Props {
  post: Post
  displayAuthor: boolean
  displayTags: boolean
  createdDate?: string
}

const PostHeader = ({ post, displayAuthor, displayTags }: Props) => {
  const author = useSelector(selectAuthorById(post.author))

  return (
    <Space
      direction="horizontal"
      style={{
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {displayAuthor && author && (
        <Space direction="horizontal">
          <Avatar src={author.avatarUrl} size={40} />
          <Space direction="vertical" size={0}>
            <Text>{`${author.firstName} ${author.lastName}`}</Text>
            <Text type="secondary">
              {moment(post.createdAt).format('DD MMM YYYY')}
            </Text>
          </Space>
        </Space>
      )}
      <Space></Space>
      <Space>
        {displayTags && (
          <Space>
            {post.tags &&
              post.tags.map((tag) => (
                <TagANTD key={tag} color="#293348">
                  <Tag id={tag}></Tag>
                </TagANTD>
              ))}
          </Space>
        )}
        <Restricted type="admin">
          <PostToolbar post={post} />
        </Restricted>
      </Space>
    </Space>
  )
}

export default PostHeader
