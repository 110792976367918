import { List } from 'antd'
import QuickLink from '../Quicklink'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../reducers/hooks'
import {
  selectQuickLinksByIds,
  swapQuickLinkOrder,
  //   swapQuickLinkOrderForTag,
} from '../../../reducers/QuickLinksReducer'
import { QuickLink as QuickLinkModel } from '../../../models/QuickLink'

interface Props {
  displayReorderArrows: Boolean
  quickLinkIds: number[]
}

const LinkList = ({ displayReorderArrows, quickLinkIds }: Props) => {
  const dispatch = useAppDispatch()

  const quickLinks = useSelector(selectQuickLinksByIds(quickLinkIds))

  return (
    <List
      itemLayout="vertical"
      dataSource={quickLinks.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))}
      bordered={false}
      style={{ padding: 0, margin: 0 }}
      rowKey={(item) => item.id}
      renderItem={(item, index) => (
        <QuickLink
          model={item}
          displayMode="large"
          upArrow={
            displayReorderArrows && index !== 0
              ? () => dispatch(swapQuickLinkOrder(item, quickLinks[index - 1]))
              : undefined
          }
          downArrow={
            displayReorderArrows && index !== quickLinks!.length - 1
              ? () => dispatch(swapQuickLinkOrder(item, quickLinks[index + 1]))
              : undefined
          }
        />
      )}
    />
  )
}

export default LinkList
