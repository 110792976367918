import { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

// MSAL imports
import { IPublicClientApplication } from '@azure/msal-browser'
import { CustomNavigationClient } from './NavigationClient'
/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
type ClientSideNavigationProps = {
  pca: IPublicClientApplication
  children: any
}
function ClientSideNavigation({ pca, children }: ClientSideNavigationProps) {
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true)
  useEffect(() => {
    setFirstRender(false)
  }, [])

  if (firstRender) {
    return null
  }

  return children
}

export default ClientSideNavigation
