import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { FloatButton, Layout, Space, theme } from 'antd'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { LayoutSider, siderCollapsedWidth, siderWidth } from './LayoutSider'
import RightHeader from './RightHeader'
import { LayoutFooter } from './LayoutFooter'
import { callMsGraph } from '../../utils/MsGraphApiCall'
import { useAppDispatch } from '../../reducers/hooks'
import { getUser, updateUserSuccess } from '../../reducers/UserReducer'
import { GraphData } from '../../models/GraphData'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../../authConfig'
import { HomeOutlined } from '@ant-design/icons'

const { Header, Content, Footer } = Layout

export const PageLayout: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [collapsed, setCollapsed] = useState(false)
  const [graphData, setGraphData] = useState<GraphData | undefined>(undefined)

  const { result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  )

  useEffect(() => {
    if (!!graphData) {
      // We already have the data, no need to call the API
      return
    }

    if (!!error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      return
    }

    if (result) {
      dispatch(getUser())
      callMsGraph().then((response) => {
        setGraphData(response)
        dispatch(
          updateUserSuccess({
            id: response.id,
            firstName: response.givenName,
            lastName: response.surname,
            email: response.mail,
          })
        )
      })
    }
  }, [error, result, graphData])

  return (
    <AuthenticatedTemplate>
      <Layout hasSider style={{ minHeight: '100vh' }}>
        <Layout
          className="site-layout"
          style={{ marginRight: collapsed ? siderCollapsedWidth : siderWidth }}
        >
          <Header
            style={{
              background: '#ffffff',
              position: 'fixed',
              width: '100vw',
              zIndex: 500,
              padding: '0 20px',
            }}
          >
            <Space
              direction="horizontal"
              style={{
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <a href="https://paris-society.com/" target="blank">
                <img
                  alt="Logo"
                  src="assets/linear_logo.png"
                  style={{
                    float: 'left',
                    height: 46,
                  }}
                />
              </a>
              <RightHeader />
            </Space>
          </Header>

          <Content
            style={{ margin: '0', paddingTop: 80, background: '#f8f8f2' }}
          >
            <Outlet />
          </Content>

          <LayoutFooter />
        </Layout>
        <LayoutSider
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
        />
      </Layout>
      <FloatButton.BackTop type="primary" visibilityHeight={0} />
    </AuthenticatedTemplate>
  )
}
