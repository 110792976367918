import { Typography } from 'antd'
import { Post as PostModel } from '../../../models/Post'
import Gallery from '../utils/Gallery'
import { Picture } from '../../../models/Picture'
import PostHeader from '../PostHeader'
import { useIntl } from 'react-intl'
const { Title, Paragraph } = Typography

interface Props {
  post: PostModel
}

const Default = ({ post }: Props) => {
  const intl = useIntl()

  const markup = { __html: post.content }

  return (
    <>
      <PostHeader displayAuthor={true} displayTags={true} post={post} />
      <Title level={4}>{post.title}</Title>
      <Paragraph style={{ whiteSpace: 'pre-line' }}>
        <div className="html-render" dangerouslySetInnerHTML={markup} />
      </Paragraph>
      {post.medias && post.medias?.length > 0 && (
        <Gallery medias={post.medias! as Picture[]} />
      )}
    </>
  )
}

export default Default
