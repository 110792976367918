import { Typography } from 'antd'
import { useIntl } from 'react-intl'
import { Post as PostModel } from '../../../models/Post'
import { Video as VideoModel } from '../../../models/Video'
import PostHeader from '../PostHeader'
const { Title, Paragraph } = Typography

interface Props {
  post: PostModel
}

const Video = ({ post }: Props) => {
  const intl = useIntl()
  const markup = { __html: post.content }

  return (
    <>
      <PostHeader displayAuthor={true} displayTags={true} post={post} />
      <Title level={4}>{post.title}</Title>
      <Paragraph style={{ whiteSpace: 'pre-line' }}>
        <div className="html-render" dangerouslySetInnerHTML={markup} />
      </Paragraph>
      <iframe
        width="100%"
        style={{ aspectRatio: '16/9', border: 0 }}
        src={(post.medias![0] as VideoModel).src}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </>
  )
}

export default Video
