import Compressor from 'compressorjs'
import { UploadFile } from 'antd'
import { RcFile } from 'antd/es/upload'

const COMPRESSION_QUALITY = 0.6

export const handleCompressedUpload = (
  e: UploadFile,
  onCompressed: (compressedFile: RcFile) => void
) => {
  const image = e as RcFile
  new Compressor(image, {
    quality: COMPRESSION_QUALITY,
    success: (compressedResult) => {
      // compressedResult has the compressed file.
      // Use the compressed file to upload the images to your server.
      onCompressed(compressedResult as RcFile)
    },
    error: () => {
      alert('Error during compressing' + image.size)
    },
  })
}

export async function asyncCompress(file: RcFile): Promise<RcFile> {
  return new Promise<RcFile>((resolve) => {
    new Compressor(file, {
      quality: COMPRESSION_QUALITY,
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        resolve(compressedResult as RcFile)
      },
      error: () => {
        console.log("Error: File wasn't compressed")
        resolve(file)
      },
    })
  })
}
