import { Button, List, Modal, Space } from 'antd'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { selectSlides } from '../../../reducers/CarouselReducer'
import SlideCell from '../SlideCell'
import { useIntl } from 'react-intl'
import { RootState } from '../../../reducers/store'
import Loading from '../../utils/Loading'

interface Props {
  onCreateTapped: () => void
  onUpdateTapped: () => void
  onConfigTapped: () => void
}

const View = ({ onCreateTapped, onUpdateTapped, onConfigTapped }: Props) => {
  const intl = useIntl()

  const slides = useSelector(selectSlides)
  const { isLoading } = useSelector((state: RootState) => state.carousel)

  return (
    <>
      {slides.map((slide, index) => (
        <SlideCell
          key={slide.id}
          slide={slide}
          updateTapped={() => onUpdateTapped()}
          canUp={index != 0}
          canDown={index != slides.length - 1}
        />
      ))}

      <Space>
        <Button onClick={() => onCreateTapped()}>
          {intl.formatMessage({ id: 'CAROUSEL.ADD_SLIDE_BUTTON' })}
        </Button>
        <Button onClick={() => onConfigTapped()}>
          {intl.formatMessage({ id: 'CAROUSEL.EDIT_CONFIG' })}
        </Button>
        <Space></Space>
        {isLoading && <Loading></Loading>}
      </Space>
    </>
  )
}

export default View
