import { schema } from 'normalizr'

const tagSchema = new schema.Entity('tag')
const quickLinkSchema = new schema.Entity('quickLink')

tagSchema.define({
  quickLinks: [quickLinkSchema],
})

quickLinkSchema.define({})

export { tagSchema, quickLinkSchema }
