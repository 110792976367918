import { Avatar, Button, Dropdown, List, Space, Typography } from 'antd'
import { MenuProps } from 'rc-menu'
import { Author } from '../../models/Author'
import { deleteAuthor, selectAuthorId } from '../../reducers/AuthorReducer'
import { useAppDispatch } from '../../reducers/hooks'
import { EllipsisOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

const Text = Typography
interface Props {
  author: Author
  editMode?: boolean
  updateTapped: () => void
}

const AuthorCell = ({ author, editMode = true, updateTapped }: Props) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Text>{intl.formatMessage({ id: 'UPDATE' })}</Text>,
      onClick: () => {
        dispatch(selectAuthorId(author.id))
        updateTapped()
      },
    },
    {
      key: '2',
      label: <Text>{intl.formatMessage({ id: 'DELETE' })}</Text>,
      disabled: author.postsCount > 0,
      onClick: () => dispatch(deleteAuthor(author)),
    },
  ]

  return (
    <>
      <List.Item style={{ border: 0, padding: '8px 0' }}>
        <Space
          style={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Space direction="horizontal">
            <Avatar shape="circle" size={50} src={author.avatarUrl} />
            <Space direction="vertical" size={0}>
              <Text>
                <b>{`${author.firstName} ${author.lastName}`}</b>
              </Text>
              <Text>
                {author.postsCount} {intl.formatMessage({ id: 'POSTS' })}
              </Text>
            </Space>
          </Space>
          {editMode && (
            <>
              <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                <Button type="text">
                  <EllipsisOutlined />
                </Button>
              </Dropdown>
            </>
          )}
        </Space>
      </List.Item>
    </>
  )
}

export default AuthorCell
