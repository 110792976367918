import axios from 'axios'
import { createSelector, createSlice, Dispatch } from '@reduxjs/toolkit'

import { Tag } from '../models/Tag'
import { TAGS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { SelectOptionType } from '../custom'
import { normalize } from 'normalizr'
import { tagSchema } from '../models/schema'
import { SBSelectRaw } from './helper'

// utils
// @types

// ----------------------------------------------------------------------
export type TagState = {
  isLoading: boolean
  error: Error | string | null
  ids: number[]
  tags: { [key: string]: Tag }
  selectedTagId: number | undefined
  showCreateModal: boolean
  showEditModal: boolean
}

const initialState: TagState = {
  isLoading: false,
  error: null,
  ids: [],
  tags: {},
  selectedTagId: undefined,
  showCreateModal: false,
  showEditModal: false,
}

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Tags
    getTagsSuccess(state, action) {
      state.tags = { ...state.tags, ...action.payload }
      state.isLoading = false
    },
    setIds(state, action) {
      state.ids = action.payload
    },

    // UPDATE EVENT
    updateTagsuccess(state, action) {
      const next = { ...state.tags }
      next[action.payload.id] = {
        ...next[action.payload.id],
        ...action.payload,
      }
      state.tags = next
      state.showEditModal = false
      state.isLoading = false
    },

    // DELETE EVENT
    deleteTagsuccess(state, action) {
      const next = { ...state.tags }
      delete next[action.payload.id]
      state.tags = next
      state.showEditModal = false
      state.isLoading = false
    },

    // SELECT EVENT
    selectTagId(state, action) {
      state.showEditModal = true
      const tagId = action.payload
      state.selectedTagId = tagId
    },

    onOpenCreateModal(state) {
      state.showCreateModal = true
    },

    onOpenUpdateModal(state) {
      state.showEditModal = true
    },

    onCloseCreateModal(state) {
      state.showCreateModal = false
      state.selectedTagId = undefined
    },

    onCloseUpdateModal(state) {
      state.showEditModal = false
      state.selectedTagId = undefined
    },
  },
})

// Reducer
export default slice.reducer

export const {
  selectTagId,
  getTagsSuccess,
  onOpenCreateModal,
  onOpenUpdateModal,
  onCloseCreateModal,
  onCloseUpdateModal,
} = slice.actions

export function getTags() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(TAGS_URL).then((response) => {
      const normalizedData = normalize(response.data.data, [tagSchema])
      dispatch(slice.actions.getTagsSuccess(normalizedData.entities.tags))
      dispatch(slice.actions.setIds(normalizedData.result))
    })
  }
}

// export function createTag(newTag: Tag) {
//   return async (dispatch: Dispatch) => {
//     newTag.id = Math.floor(Math.random() * 10000)
//     dispatch(slice.actions.createTagsuccess(newTag))
//   }
// }

// export function updateTag(tag: Tag) {
//   return async (dispatch: Dispatch) => {
//     dispatch(slice.actions.updateTagsuccess(tag))
//   }
// }

// ----------------------------------------------------------------------

// export function deleteTag(tag: Tag) {
//   return async (dispatch: Dispatch) => {
//     dispatch(slice.actions.deleteTagsuccess(tag.id))
//   }
// }

// ----------------------------------------------------------------------

export function swapTagOrder(a: Tag, b: Tag) {
  return async (dispatch: AppDispatch) => {
    const newA: Tag = {
      ...a,
      quickLinkOrder: b.quickLinkOrder,
    }
    const newB: Tag = {
      ...b,
      quickLinkOrder: a.quickLinkOrder,
    }

    dispatch(slice.actions.updateTagsuccess(newA))
    dispatch(slice.actions.updateTagsuccess(newB))
  }
}

/**
 * Selectors
 */
const selectRawItems: SBSelectRaw<{ [key: string]: Tag }> = (
  state: RootState
) => state[slice.name].tags
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedTagId

export const selectTags = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectSelectedTag = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectTagById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectTagsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

export const selectTagsWithQuickLinks = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items)
      .map((i) => i[1])
      .filter((t) => (t.quickLinks ?? []).length > 0)
  )

export const selectTagsFormFormatted = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((tag: any) => ({
      value: String(tag[1].id),
      label: tag[1].name,
    }))
  )
