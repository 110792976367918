import React from 'react'
import { Card, Space, Typography } from 'antd'
import { HeartFilled } from '@ant-design/icons'
import { Post as PostModel } from '../../models/Post'
import { useAppDispatch } from '../../reducers/hooks'
import { togglePostLike } from '../../reducers/PostReducer'
import Video from './types/Video'
import Quote from './types/Quote'
import Default from './types/Default'
const Title = Typography

interface Props {
  post: PostModel
}

const Post = ({ post }: Props) => {
  const dispatch = useAppDispatch()

  return (
    <Card
      actions={[
        <Space
          key={1}
          onClick={() => dispatch(togglePostLike(post))}
          style={{ width: '100%', padding: '0 25px' }}
        >
          <HeartFilled
            className={post.isLiked ? 'liked-icon' : ''}
            style={{ fontSize: 30, color: post.isLiked ? 'red' : 'grey' }}
          />
          <Title> {post.likes}</Title>
        </Space>,
      ]}
    >
      {post.type === 'default' && <Default post={post} />}
      {post.type === 'video' && <Video post={post} />}
      {post.type === 'quote' && <Quote post={post} />}
    </Card>
  )
}

export default Post
