import { Button, Modal } from 'antd'
import { useState } from 'react'
import Update from './form/Update'
import CreateSlide from './form/CreateSlide'
import UpdateSlide from './form/UpdateSlide'
import View from './form/View'

const CarouselManager = () => {
  const [action, setAction] = useState<'create' | 'update' | 'view' | 'config'>(
    'view'
  )
  return (
    <>
      {action == 'view' && (
        <View
          onCreateTapped={() => setAction('create')}
          onUpdateTapped={() => setAction('update')}
          onConfigTapped={() => setAction('config')}
        />
      )}

      {action == 'create' && (
        <CreateSlide
          onFinish={() => {
            setAction('view')
          }}
        />
      )}

      {action == 'update' && (
        <UpdateSlide
          onFinish={() => {
            setAction('view')
          }}
        />
      )}
      {action == 'config' && (
        <Update
          onFinish={() => {
            setAction('view')
          }}
        />
      )}
    </>
  )
}

export default CarouselManager
