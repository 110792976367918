import { Avatar, Button, Dropdown, List, Space, Typography } from 'antd'
import { MenuProps } from 'rc-menu'
import { useAppDispatch } from '../../reducers/hooks'
import { EllipsisOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { User } from '../../models/User'
import { useSelector } from 'react-redux'
import { selectUser, updateAdminRights } from '../../reducers/UserReducer'

const Text = Typography
interface Props {
  admin: User
}

const AdminCell = ({ admin }: Props) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const loggedUser = useSelector(selectUser)

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Text>{intl.formatMessage({ id: 'DELETE' })}</Text>,
      onClick: () => dispatch(updateAdminRights(admin, 'visitor')),
    },
  ]

  return (
    <>
      <List.Item style={{ border: 0, padding: '8px 0' }}>
        <Space
          style={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Space direction="horizontal">
            <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              {admin && admin.firstName && admin.lastName
                ? admin!.firstName.slice(0, 1) + admin!.lastName.slice(0, 1)
                : '--'}
            </Avatar>
            <Space direction="vertical" size={0}>
              <Text>
                <b>{`${admin.firstName} ${admin.lastName}`}</b>
              </Text>
              <Text>{admin.email}</Text>
            </Space>
          </Space>

          {admin.id !== loggedUser!.id && (
            <Dropdown menu={{ items }} placement="bottomLeft" arrow>
              <Button type="text">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          )}
        </Space>
      </List.Item>
    </>
  )
}

export default AdminCell
