export interface Status {
  id: number
  name: string
}

export const statuses = [
  { label: 'Draft', value: 1 },
  { label: 'Published', value: 2 },
]

export const STATUS_DRAFT = 1
export const STATUS_PUBLISHED = 2
