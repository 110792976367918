import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Input, Space, Upload, UploadFile } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'
import { Author } from '../../../models/Author'
import {
  selectSelectedAuthor,
  updateAuthor,
} from '../../../reducers/AuthorReducer'
import { useIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons'
import { handleCompressedUpload } from '../../../utils/ImageCompressor'
import ImgCrop from 'antd-img-crop'

interface Props {
  onFinish: () => void
}

const Update = ({ onFinish }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [form] = Form.useForm()

  const selectedAuthor = useSelector(selectSelectedAuthor())

  const [file, setFile] = useState<UploadFile | undefined>(undefined)
  const [formData, setFormData] = useState<Author | undefined>(undefined)

  const onBack = () => {
    form.resetFields()
    onFinish()
  }

  const onFormSubmit = () => {
    if (formData === undefined) {
      return
    }

    dispatch(updateAuthor({ ...formData, avatar: file }))
    form.resetFields()
    onFinish()
  }

  useEffect(() => {
    if (!selectedAuthor) {
      return
    }

    setFormData(selectedAuthor)
    form.setFieldsValue(selectedAuthor)
  }, [form, selectedAuthor])

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(_, allFields) => {
        setFormData({ ...formData, ...allFields })
      }}
      initialValues={selectedAuthor}
      onFinish={onFormSubmit}
    >
      <Form.Item
        name="firstName"
        label={intl.formatMessage({ id: 'FIRST_NAME' })}
        rules={[
          {
            type: 'string',
            message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_STRING' }),
          },
          {
            required: true,
            message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={intl.formatMessage({ id: 'LAST_NAME' })}
        rules={[
          {
            type: 'string',
            message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_STRING' }),
          },
          {
            required: true,
            message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="avatar"
        label={intl.formatMessage({ id: 'FIELD_LABEL.FILES_OPTIONAL' })}
      >
        <ImgCrop rotationSlider cropShape="round">
          <Upload
            maxCount={1}
            accept="image/*"
            listType="picture-card"
            beforeUpload={(file) => {
              handleCompressedUpload(file, (compressedFile) =>
                setFile(compressedFile)
              )
              return false
            }}
            onRemove={() => {
              setFile(undefined)
            }}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>
                {intl.formatMessage({ id: 'SELECT_FILES' })}
              </div>
            </div>
          </Upload>
        </ImgCrop>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: 'SUBMIT' })}
          </Button>
          <Button onClick={onBack}>
            {intl.formatMessage({ id: 'CANCEL' })}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default Update
