import { Modal } from 'antd'
import { useAppDispatch } from '../../reducers/hooks'
import {
  onCloseCreateAuthorModal,
  onCloseUpdateAuthorModal,
} from '../../reducers/AuthorReducer'
import AuthorManager from './AuthorManager'

interface Props {
  show?: boolean
  onFinish: () => void
}

const AuthorModal = ({ show, onFinish }: Props) => {
  const dispatch = useAppDispatch()

  return (
    <>
      <Modal open={show} onCancel={onFinish} footer={[]}>
        <AuthorManager />
      </Modal>
    </>
  )
}

export default AuthorModal
