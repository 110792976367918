import { Button, Typography } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SlideModel } from '../../models/Slide'
import { Buffer } from 'buffer'
const { Title, Paragraph } = Typography

interface Props {
  slide: SlideModel
}

const Slide = ({ slide }: Props) => {
  const intl = useIntl()

  const [image, setImage] = useState<string | undefined>(undefined)

  useEffect(() => {
    axios
      .get(slide.pictureUrl, { responseType: 'arraybuffer' })
      .then((response) =>
        setImage(Buffer.from(response.data, 'binary').toString('base64'))
      )
  }, [slide.pictureUrl])

  return (
    <div
      style={{
        backgroundImage: `url(data:;base64,${image})`,
        backgroundSize: 'cover',
        height: 500,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          background: '#00000055',
          padding: 30,
        }}
      >
        <div
          style={{
            bottom: 30,
            position: 'absolute',
            maxWidth: 720,
            color: '#fff',
          }}
        >
          <Title level={4} style={{ color: '#fff' }}>
            {slide.title}
          </Title>
          <Paragraph style={{ color: '#fff' }}>{slide.body}</Paragraph>
          <Button
            type="primary"
            size="large"
            onClick={() => window.open(slide.link, '_blank')}
          >
            {intl.formatMessage({ id: 'CAROUSEL.VIEW_MORE' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Slide
