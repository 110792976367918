import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Input, InputNumber, Select, Space, Switch } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'
import { RootState } from '../../../reducers/store'
import { Author } from '../../../models/Author'
import {
  selectSelectedAuthor,
  updateAuthor,
} from '../../../reducers/AuthorReducer'
import { CarouselConfig } from '../../../models/CarouselConfig'
import { selectConfig, updateConfig } from '../../../reducers/CarouselReducer'
import { useIntl } from 'react-intl'

interface Props {
  onFinish: () => void
}

const Update = ({ onFinish }: Props) => {
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const config = useSelector(selectConfig)

  const [formData, setFormData] = useState<CarouselConfig | undefined>(
    undefined
  )

  const onFormSubmit = () => {
    if (formData === undefined) {
      return
    }

    dispatch(updateConfig(formData))
    form.resetFields()
    onFinish()
  }

  useEffect(() => {
    if (!config) {
      return
    }
    // Mapping the selectedQuickLink's tags allow to fill the Select tags properly

    setFormData(config)
    form.setFieldsValue(config)
  }, [form, config])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allFields) => {
          setFormData({ ...formData, ...allFields })
        }}
        initialValues={config}
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="duration"
          label={intl.formatMessage({ id: 'CAROUSEL.DURATION' })}
          rules={[
            {
              type: 'number',
              message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_NUMBER' }),
            },
            {
              required: true,
              message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="autoplay"
          label={intl.formatMessage({ id: 'CAROUSEL.AUTOPLAY' })}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="hide"
          label={intl.formatMessage({ id: 'CAROUSEL.HIDE' })}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({ id: 'SUBMIT' })}
            </Button>
            <Button type="default" htmlType="reset" onClick={() => onFinish()}>
              {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default Update
