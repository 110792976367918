import { createSelector, createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { User, UserType } from '../models/User'
import { ADMINS_URL, LOGIN_URL, ME_INFOS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'

// utils
// @types

// ----------------------------------------------------------------------
export type Userstate = {
  isLoading: boolean
  error: Error | string | null
  user: User | undefined
  admins: User[]
}

const initialState: Userstate = {
  isLoading: false,
  error: null,
  user: undefined,
  admins: [],
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Admin
    getAdminsSuccess(state, action) {
      state.isLoading = false
      state.admins = action.payload
    },

    // CREATE Admin
    createAdminSuccess(state, action) {
      state.isLoading = false
      state.admins = [...state.admins, action.payload]
    },

    // UPDATE EVENT
    updateUserSuccess(state, action) {
      state.isLoading = false
      state.user = { ...state.user, ...action.payload }
    },

    updateAdminsSuccess(state, action) {
      state.isLoading = false
      state.admins = state.admins.map((admin) => {
        return admin.id === action.payload.id ? action.payload : admin
      })
    },

    // DELETE EVENT
    deleteAdminsuccess(state, action) {
      state.isLoading = false
      const adminId = action.payload
      state.admins = state.admins.filter((admin) => admin.id !== adminId)
    },
  },
})

// Reducer
export default slice.reducer

export const { updateUserSuccess } = slice.actions

export function loginUser(user: User) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .post(LOGIN_URL)
      .then((_response) => dispatch(slice.actions.updateUserSuccess(user)))
  }
}

export function getUser() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .get(ME_INFOS_URL)
      .then((response) =>
        dispatch(slice.actions.updateUserSuccess(response.data.data))
      )
  }
}

/**
 * Admin actions
 */
export function getAdmins() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(ADMINS_URL).then((response) => {
      const admins = response.data.data
      dispatch(slice.actions.getAdminsSuccess(admins))
    })
  }
}

export function createAdmin(admin: User) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.hasError(undefined))
    dispatch(slice.actions.startLoading())
    await axios
      .post(ADMINS_URL, { email: admin.email })
      .then((response) =>
        dispatch(slice.actions.createAdminSuccess(response.data.data))
      )
      .catch((_reason) =>
        dispatch(slice.actions.hasError('ADMIN.CREATION.ERROR'))
      )
  }
}

export function updateAdminRights(admin: User, userType: UserType) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.updateAdminsSuccess({ ...admin, type: userType }))
    await axios
      .put(`${ADMINS_URL}/${admin.id}`, { type: userType })
      .then((_response) => {
        dispatch(slice.actions.stopLoading())
      })
  }
}

/**
 * Selectors
 */
export const selectUser = (state: RootState): User | undefined =>
  state.user.user

export const selectRawAdmins = (state: RootState): User[] => state.user.admins

export const selectUserType = () =>
  createSelector<any, UserType>([selectUser], (user: User) =>
    user && user.type ? user.type : 'visitor'
  )
export const selectAdmins = () =>
  createSelector<any, User[]>([selectRawAdmins], (admins: User[]) =>
    admins.filter((e) => e.type === 'admin')
  )
