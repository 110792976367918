import { List } from 'antd'
import QuickLink from '../Quicklink'
import { useSelector } from 'react-redux'
import { selectTagsWithQuickLinks } from '../../../reducers/TagReducer'

interface Props {}

const LinkListCollapsed = ({}: Props) => {
  const tags = useSelector(selectTagsWithQuickLinks())

  return (
    <>
      {tags.map((tag, index) => (
        <List
          key={index}
          itemLayout="vertical"
          dataSource={tag.quickLinks}
          bordered={false}
          style={{ padding: 0, margin: 0 }}
          renderItem={(item) => <QuickLink id={item} displayMode="small" />}
        />
      ))}
    </>
  )
}

export default LinkListCollapsed
