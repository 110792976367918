import { Button, Form, Input, Space, Upload, UploadFile } from 'antd'
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { minimumFilesCountRule, requiredRule } from '../../../utils/rules'
import ProtectedImage from '../../utils/ProtectedImage'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { Picture } from '../../../models/Picture'

interface Props {
  name: string
  multiple: boolean
  defaultFiles: UploadFile<any>[]
}

export interface UpdateImageFromFileRefInterface {
  handleReset: () => void
}

const UpdateImageFromFile = forwardRef(
  (
    { name, multiple, defaultFiles }: Props,
    ref: Ref<UpdateImageFromFileRefInterface>
  ) => {
    useImperativeHandle(ref, () => ({
      handleReset,
    }))

    const handleReset = () => setIdsToDelete([])

    const intl = useIntl()

    const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState<
      UploadFile<any>[]
    >([])
    const [idsToDelete, setIdsToDelete] = useState<string[]>([])

    useEffect(() => {
      setAlreadyUploadedFiles(defaultFiles)
      setIdsToDelete([])
    }, [defaultFiles])

    return (
      <Space direction="horizontal">
        <Form.Item
          name={name}
          rules={
            !alreadyUploadedFiles.length
              ? [requiredRule(intl), minimumFilesCountRule(intl)]
              : []
          }
          label={intl.formatMessage({ id: 'FIELD_LABEL.FILES' })}
        >
          <Upload
            multiple={multiple ? true : undefined}
            maxCount={multiple ? undefined : 1}
            accept="image/*"
            listType="picture-card"
            beforeUpload={() => {
              if (!multiple) {
                setIdsToDelete(alreadyUploadedFiles.map((f) => f.uid))
                setAlreadyUploadedFiles([])
              }
              return false
            }}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>
                {intl.formatMessage({ id: 'SELECT_FILES' })}
              </div>
            </div>
          </Upload>
        </Form.Item>
        {alreadyUploadedFiles.map((file) => (
          <Space direction="vertical" key={file.uid}>
            <ProtectedImage
              picture={{ src: file.url } as Picture}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
              }}
            ></ProtectedImage>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                setAlreadyUploadedFiles(
                  alreadyUploadedFiles.filter((f) => f.uid !== file.uid)
                )
                setIdsToDelete([...idsToDelete, file.uid])
              }}
            >
              {intl.formatMessage({ id: 'DELETE' })}
            </Button>
          </Space>
        ))}
        {idsToDelete.map((id) => (
          <Form.Item
            key={id}
            initialValue={id}
            style={{ display: 'none' }}
            name={['filesToDelete', id]}
          >
            <Input type="hidden" value={id} />
          </Form.Item>
        ))}
      </Space>
    )
  }
)

UpdateImageFromFile.displayName = 'UpdateImageFromFile'

export default UpdateImageFromFile
