import { Button, List, Modal, Space } from 'antd'
import Create from './form/Create'
import Update from './form/Update'
import { useSelector } from 'react-redux'
import AuthorCell from './AuthorCell'
import { RootState } from '../../reducers/store'
import { useState } from 'react'
import Loading from '../utils/Loading'
import { useIntl } from 'react-intl'

const AuthorManager = () => {
  const intl = useIntl()
  const [action, setAction] = useState<'create' | 'update' | 'view'>('view')
  const { authors, isLoading } = useSelector(
    (state: RootState) => state.authors
  )

  return (
    <>
      {action == 'view' && (
        <>
          <List
            itemLayout="vertical"
            dataSource={authors}
            bordered={false}
            style={{ padding: 0, margin: 0 }}
            renderItem={(item) => (
              <AuthorCell
                author={item}
                updateTapped={() => setAction('update')}
              />
            )}
          />
          <Space direction="horizontal">
            <Button onClick={() => setAction('create')}>
              {intl.formatMessage({ id: 'AUTHOR.ADD_AUTHOR_BUTTON' })}
            </Button>
            <Space></Space>
            {isLoading && <Loading></Loading>}
          </Space>
        </>
      )}

      {action == 'create' && (
        <Create
          onFinish={() => {
            setAction('view')
          }}
        />
      )}

      {action == 'update' && (
        <Update
          onFinish={() => {
            setAction('view')
          }}
        />
      )}
    </>
  )
}

export default AuthorManager
