import { useSelector } from 'react-redux'
import { UserType } from '../../models/User'
import { selectUserType } from '../../reducers/UserReducer'

interface Props {
  type: UserType
  children: React.ReactNode
}

const Restricted = ({ type, children }: Props) => {
  const userType = useSelector(selectUserType())

  return <>{type === userType && children}</>
}

export default Restricted
