import { useState } from 'react'
import { Collapse, List, Space } from 'antd'
import QuickLink from '../Quicklink'
import {
  onOpenCreateModal,
  selectQuickLinksByIds,
  //   swapQuickLinkOrderForTag,
} from '../../../reducers/QuickLinksReducer'
import { useAppDispatch } from '../../../reducers/hooks'
import { useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import GroupHeader from '../list/GroupHeader'
import Restricted from '../../restricted/Restricted'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { selectTagsByIds, swapTagOrder } from '../../../reducers/TagReducer'
import LinkList from './LinkList'

const { Panel } = Collapse

interface Props {
  displayReorderArrows: Boolean
  tagIds: number[]
}

const LinkListByTags = ({ displayReorderArrows, tagIds }: Props) => {
  const dispatch = useAppDispatch()

  const [activeKey, setActiveKey] = useState<string[]>([])

  const tags = useSelector(selectTagsByIds(tagIds))
    .filter((t) => (t.quickLinks ?? []).length > 0)
    .sort((a, b) => (a.quickLinkOrder ?? 0) - (b.quickLinkOrder ?? 0))

  return (
    <Collapse
      collapsible="header"
      ghost
      activeKey={activeKey}
      onChange={(key) => setActiveKey(Array.isArray(key) ? key : [key])}
    >
      {tags.map((tag, index) => (
        <Panel
          key={tag.id}
          header={<GroupHeader tagId={tag.id} />}
          extra={
            <Restricted type="admin">
              <Space>
                {displayReorderArrows && index != 0 && (
                  <UpOutlined
                    onClick={() => dispatch(swapTagOrder(tag, tags[index - 1]))}
                  />
                )}
                {displayReorderArrows && index !== tags.length - 1 && (
                  <DownOutlined
                    onClick={() => dispatch(swapTagOrder(tag, tags[index + 1]))}
                  />
                )}
                {!displayReorderArrows && (
                  <PlusOutlined onClick={() => dispatch(onOpenCreateModal())} />
                )}
              </Space>
            </Restricted>
          }
        >
          <LinkList
            quickLinkIds={tag.quickLinks ?? []}
            displayReorderArrows={displayReorderArrows}
          />
        </Panel>
      ))}
    </Collapse>
  )
}

export default LinkListByTags
