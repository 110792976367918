import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from 'antd'
import { selectTagsFormFormatted } from '../../../reducers/TagReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { requiredRule, stringRule, urlRule } from '../../../utils/rules'
import {
  selectSelectedQuickLink,
  updateQuickLink,
} from '../../../reducers/QuickLinksReducer'
import { Picture } from '../../../models/Picture'
import { asyncCompress } from '../../../utils/ImageCompressor'
import ImageFromLink from '../components/ImageFromLink'
import UpdateImageFromFile, {
  UpdateImageFromFileRefInterface,
} from '../components/UpdateImageFromFile'

interface Props {
  onFinish: () => void
}

const Update = ({ onFinish }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const imageRef = useRef<UpdateImageFromFileRefInterface>(null)

  const [isImageFromLink, setIsImageFromLink] = useState<boolean>(true)

  const tagOptions = useSelector(selectTagsFormFormatted())
  const { isLoading } = useAppSelector((state) => state.quickLink)
  const selectedQuickLink = useSelector(selectSelectedQuickLink())

  const onFormSubmit = async () => {
    const compressedImage =
      form.getFieldValue('image_method') === 'file' &&
      form.getFieldValue('image').fileList
        ? await asyncCompress(
            form.getFieldValue('image').fileList[0].originFileObj
          )
        : undefined

    dispatch(
      updateQuickLink({
        ...form.getFieldsValue(),
        image: compressedImage,
        filesToDelete:
          form.getFieldValue('image_method') === 'link'
            ? [(selectedQuickLink?.image as Picture).id]
            : Object.values(form.getFieldValue('filesToDelete') ?? {}),
      })
    ).then(() => {
      form.resetFields()
      imageRef.current?.handleReset()
      onFinish()
    })
  }

  useEffect(() => {
    if (!selectedQuickLink) {
      return
    }

    form.setFieldsValue(selectedQuickLink)
    form.setFieldValue(
      'tags',
      selectedQuickLink.tags.map((t) => String(t))
    )
    form.setFieldValue(
      'image_method',
      selectedQuickLink?.image ? 'file' : 'link'
    )

    setIsImageFromLink(!selectedQuickLink?.image)
  }, [selectedQuickLink])

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFormSubmit}>
        <Form.Item
          name="id"
          rules={[requiredRule(intl)]}
          style={{ display: 'none' }}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="sourceId"
          rules={[requiredRule(intl)]}
          style={{ display: 'none' }}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="name"
          label={intl.formatMessage({ id: 'NAME' })}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="url"
          label={intl.formatMessage({ id: 'LINK' })}
          rules={[requiredRule(intl), urlRule(intl)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tags"
          label={intl.formatMessage({ id: 'TAGS' })}
          rules={[requiredRule(intl)]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            options={tagOptions}
            filterOption={true}
            showSearch={true}
            showArrow={true}
            optionLabelProp="label"
          />
        </Form.Item>

        <Form.Item
          name="image_method"
          label={intl.formatMessage({ id: 'QUICKLINK.IMAGE_METHOD' })}
          rules={[requiredRule(intl)]}
        >
          <Radio.Group
            optionType="button"
            defaultValue={selectedQuickLink?.image ? 'file' : 'link'}
            options={[
              { label: 'From link', value: 'link' },
              { label: 'From file', value: 'file' },
            ]}
            onChange={(e: RadioChangeEvent) => {
              setIsImageFromLink(e.target.value === 'link')
            }}
          />
        </Form.Item>
        {isImageFromLink && <ImageFromLink />}
        {!isImageFromLink && (
          <UpdateImageFromFile
            name="image"
            ref={imageRef}
            multiple={false}
            defaultFiles={
              selectedQuickLink?.image
                ? [
                    {
                      uid: `${(selectedQuickLink?.image as Picture).id}`,
                      name: (selectedQuickLink?.image as Picture).name,
                      status: 'done',
                      url: (selectedQuickLink?.image as Picture).src,
                    },
                  ]
                : []
            }
          />
        )}

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {intl.formatMessage({ id: 'SUBMIT' })}
            </Button>
            <Button onClick={onFinish} disabled={isLoading}>
              {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default Update
