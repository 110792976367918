import React from 'react'
import { Modal } from 'antd'
import Create from './form/Create'
import Update from './form/Update'
import { useAppDispatch } from '../../reducers/hooks'
import {
  onCloseCreateModal,
  onCloseUpdateModal,
} from '../../reducers/PostReducer'
import { Post } from '../../models/Post'

interface Props {
  show?: boolean
  action: 'create' | 'update'
  post?: Post
}

const PostModal = ({ show, action, post }: Props) => {
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(onCloseCreateModal())
    dispatch(onCloseUpdateModal())
  }

  return (
    <>
      <Modal open={show} onCancel={handleClose} footer={[]} width={1000}>
        {action === 'create' && <Create onFinish={handleClose} />}
        {action === 'update' && <Update onFinish={handleClose} />}
      </Modal>
    </>
  )
}

export default PostModal
