import { Alert } from 'antd'
import { useIntl } from 'react-intl'

const ImageFromLink = () => {
  const intl = useIntl()

  return (
    <Alert
      type="info"
      style={{ marginBottom: '20px' }}
      message={intl.formatMessage({
        id: 'QUICKLINK.IMAGE_METHOD_EXPLANATION',
      })}
    />
  )
}

export default ImageFromLink
