import { Button, Dropdown, MenuProps, Select, Space, Typography } from 'antd'
import { useAppDispatch } from '../../reducers/hooks'
import {
  deletePost,
  selectPostId,
  updatePostStatus,
  togglePostPin,
  resetPostsQuery,
} from '../../reducers/PostReducer'
import { EllipsisOutlined, PushpinOutlined } from '@ant-design/icons'
import { Post } from '../../models/Post'
import { statuses } from '../../models/Status'
import { useIntl } from 'react-intl'

const { Text } = Typography

interface Props {
  post: Post
}

const PostToolbar = ({ post }: Props) => {
  const intl = useIntl()

  const dispatch = useAppDispatch()

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: <Text>{intl.formatMessage({ id: 'UPDATE' })}</Text>,
      onClick: () => dispatch(selectPostId(post.id)),
    },
    {
      key: 2,
      label: <Text>{intl.formatMessage({ id: 'DELETE' })}</Text>,
      onClick: () => dispatch(deletePost(post)),
    },
  ]

  return (
    <Space>
      <Select
        defaultValue={post.status}
        style={{ width: 120 }}
        options={statuses}
        onChange={(value) => dispatch(updatePostStatus(post, value))}
      />
      <Button
        icon={<PushpinOutlined />}
        onClick={() => {
          dispatch(togglePostPin(post))
        }}
        type={post.isPinned ? 'primary' : 'default'}
      />
      <Dropdown menu={{ items }} placement="bottomLeft" arrow>
        <Button type="text">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    </Space>
  )
}

export default PostToolbar
