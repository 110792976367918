import axios from 'axios'
import { Buffer } from 'buffer'
import { Image, List } from 'antd'
import { useEffect, useState } from 'react'
import { Picture } from '../../../models/Picture'
import ProtectedImage from '../../utils/ProtectedImage'

interface Props {
  medias: Picture[]
}

const Gallery = ({ medias }: Props) => {
  return medias.length === 1 ? (
    <ProtectedImage picture={medias[0]}></ProtectedImage>
  ) : (
    <Image.PreviewGroup>
      <List
        grid={{ gutter: 0, column: 4 }}
        dataSource={medias}
        renderItem={(media) => (
          <List.Item style={{ padding: 5 }}>
            <div
              style={{
                width: '100%',
                lineHeight: 1,
                display: 'block',
                overflow: 'hidden',
                position: 'relative',
                paddingTop: '100%',
                borderRadius: 8,
              }}
            >
              <div
                className="grid-image"
                style={{
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              >
                <ProtectedImage picture={media}></ProtectedImage>
              </div>
            </div>
          </List.Item>
        )}
      />
    </Image.PreviewGroup>
  )
}

export default Gallery
