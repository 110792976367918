import { useSelector } from 'react-redux'
import { selectTagById } from '../../../reducers/TagReducer'

interface Props {
  tagId: number
}

const GroupHeader = ({ tagId }: Props) => {
  const tag = useSelector(selectTagById(tagId))

  return <>{tag && tag.name}</>
}

export default GroupHeader
