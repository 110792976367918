import axios from 'axios'
import { RootState } from './store'
import { Author, AuthorCreation, AuthorUpdate } from '../models/Author'
import { AUTHORS_URL } from '../utils/urls'
import { SelectOptionType } from '../custom'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from './store'
import { RcFile } from 'antd/es/upload'

// utils
// @types

// ----------------------------------------------------------------------
export type Authorstate = {
  isLoading: boolean
  error: Error | string | null
  authors: Author[]
  selectedAuthorId: number | undefined
  showCreateModal: boolean
  showEditModal: boolean
}

const initialState: Authorstate = {
  isLoading: false,
  error: null,
  authors: [],
  selectedAuthorId: undefined,
  showCreateModal: false,
  showEditModal: false,
}

const slice = createSlice({
  name: 'author',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Authors
    getAuthorsSuccess(state, action) {
      state.isLoading = false
      state.authors = action.payload
    },

    // CREATE EVENT
    createAuthorsuccess(state, action) {
      const newAuthor = action.payload
      state.isLoading = false
      state.authors = [...state.authors, newAuthor]
    },

    // UPDATE EVENT
    updateAuthorsuccess(state, action) {
      state.isLoading = false
      state.authors = state.authors.map((author) => {
        if (author.id === action.payload.id) {
          state.showEditModal = false
          return action.payload
        }
        return author
      })
    },

    // DELETE EVENT
    deleteAuthorsuccess(state, action) {
      const authorId = action.payload
      state.authors = state.authors.filter((author) => author.id !== authorId)
      state.isLoading = false
    },

    // SELECT EVENT
    selectAuthorId(state, action) {
      state.showEditModal = true
      const authorId = action.payload
      state.selectedAuthorId = authorId
    },

    onOpenCreateAuthorModal(state) {
      state.showCreateModal = true
    },

    onOpenUpdateAuthorModal(state) {
      state.showEditModal = true
    },

    onCloseCreateAuthorModal(state) {
      state.showCreateModal = false
      state.selectedAuthorId = undefined
    },

    onCloseUpdateAuthorModal(state) {
      state.showEditModal = false
      state.selectedAuthorId = undefined
    },
  },
})

// Reducer
export default slice.reducer

export const {
  selectAuthorId,
  onOpenCreateAuthorModal,
  onOpenUpdateAuthorModal,
  onCloseCreateAuthorModal,
  onCloseUpdateAuthorModal,
} = slice.actions

export function getAuthors() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(AUTHORS_URL).then((response) => {
      const author = response.data.data
      dispatch(slice.actions.getAuthorsSuccess(author))
    })
  }
}

export function createAuthor(newAuthor: AuthorCreation) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    const authorToSubmit = new FormData()
    authorToSubmit.append('firstName', newAuthor.firstName)
    authorToSubmit.append('lastName', newAuthor.lastName)
    authorToSubmit.append('avatar', newAuthor.avatar as RcFile)

    await axios.post(AUTHORS_URL, authorToSubmit).then((response) => {
      const authorCreated = response.data.data
      dispatch(slice.actions.createAuthorsuccess(authorCreated))
    })
  }
}

export function updateAuthor(author: AuthorUpdate) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    const authorToSubmit = new FormData()
    authorToSubmit.append('firstName', author.firstName)
    authorToSubmit.append('lastName', author.lastName)
    if (author.avatar !== undefined) {
      authorToSubmit.append('avatar', author.avatar as RcFile)
    }

    await axios
      .post(`${AUTHORS_URL}/${author.id}`, authorToSubmit)
      .then((response) => {
        const authorUpdated = response.data.data
        dispatch(slice.actions.updateAuthorsuccess(authorUpdated))
      })
  }
}

// ----------------------------------------------------------------------

export function deleteAuthor(author: Author) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .delete(`${AUTHORS_URL}/${author.id}`)
      .then((_response) =>
        dispatch(slice.actions.deleteAuthorsuccess(author.id))
      )
  }
}

/**
 * Selectors
 */
export const selectAuthors = (state: RootState): Author[] =>
  state.authors.authors

export const selectSelectedAuthorId = (state: RootState): number | undefined =>
  state.authors.selectedAuthorId
export const selectAuthorById = (id: number) =>
  createSelector<any, Author | undefined>(
    [selectAuthors],
    (authors: Author[]) => (authors ?? []).find((a) => a.id === id)
  )

export const selectSelectedAuthor = () =>
  createSelector<any, Author | undefined>(
    [selectAuthors, selectSelectedAuthorId],
    (authors: Author[], selectedAuthorId: number | undefined) =>
      (authors ?? []).find((q) => q.id === selectedAuthorId)
  )

export const selectAuthorFormFormatted = () =>
  createSelector<any, SelectOptionType[]>(
    [selectAuthors],
    (authors: Author[]) =>
      (authors ?? []).map((author) => ({
        value: String(author.id),
        label: `${author.firstName} ${author.lastName}`,
      }))
  )
