import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { QuickLink } from '../models/QuickLink'
import { QUICK_LINKS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { getTags, getTagsSuccess } from './TagReducer'
import { normalize } from 'normalizr'
import { tagSchema } from '../models/schema'
import { SBSelectRaw } from './helper'
import { Tag } from '../models/Tag'
import { RcFile } from 'antd/es/upload'

// utils
// @types

// ----------------------------------------------------------------------
export type QuickLinkState = {
  isLoading: boolean
  error: Error | string | undefined
  ids: number[]
  quickLinks: { [key: string]: QuickLink }
  selectedQuickLinkId: number | undefined
  showCreateModal: boolean
  showEditModal: boolean
}

const initialState: QuickLinkState = {
  isLoading: false,
  error: undefined,
  ids: [],
  quickLinks: {},
  selectedQuickLinkId: undefined,
  showCreateModal: false,
  showEditModal: false,
}

const slice = createSlice({
  name: 'quickLink',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET QuickLinks
    getQuickLinksSuccess(state, action) {
      state.quickLinks = { ...state.quickLinks, ...action.payload }
      state.isLoading = false
    },
    setIds(state, action) {
      state.ids = action.payload
    },

    // UPDATE EVENT
    updateQuickLinksuccess(state, action) {
      const next = { ...state.quickLinks }
      next[action.payload.id] = {
        ...next[action.payload.id],
        ...action.payload,
      }
      state.quickLinks = next
      state.showEditModal = false
      state.isLoading = false
    },

    // DELETE EVENT
    deleteQuickLinksuccess(state, action) {
      const next = { ...state.quickLinks }
      delete next[action.payload.id]
      state.quickLinks = next
      state.isLoading = false
    },

    // SELECT EVENT
    selectQuickLinkId(state, action) {
      state.showEditModal = true
      const quickLinkId = action.payload
      state.selectedQuickLinkId = quickLinkId
    },

    onOpenCreateModal(state) {
      state.showCreateModal = true
    },

    onOpenUpdateModal(state) {
      state.showEditModal = true
    },

    onCloseCreateModal(state) {
      state.showCreateModal = false
      state.selectedQuickLinkId = undefined
    },

    onCloseUpdateModal(state) {
      state.showEditModal = false
      state.selectedQuickLinkId = undefined
    },
  },
})

// Reducer
export default slice.reducer

export const {
  selectQuickLinkId,
  onOpenCreateModal,
  onOpenUpdateModal,
  onCloseCreateModal,
  onCloseUpdateModal,
} = slice.actions

export function getQuickLinks() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(`${QUICK_LINKS_URL}/by-tags`).then((response) => {
      const normalizedData = normalize(response.data.data, [tagSchema])
      dispatch(getTagsSuccess(normalizedData.entities.tag ?? {}))
      dispatch(
        slice.actions.getQuickLinksSuccess(
          normalizedData.entities.quickLink ?? {}
        )
      )
      dispatch(
        slice.actions.setIds(
          Object.keys(normalizedData.entities.quickLink ?? {})
        )
      )
    })
  }
}

export function createQuickLink(newQuickLink: QuickLink) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    const data = new FormData()
    data.append('name', newQuickLink.name)
    data.append('url', newQuickLink.url)

    newQuickLink.tags.forEach((tag) => data.append('tags[]', tag.toString()))

    if (newQuickLink.image !== undefined) {
      data.append('image', newQuickLink.image as RcFile)
    }

    await axios.post(QUICK_LINKS_URL, data).then((response) => {
      dispatch(getTags())
      //Do not proceed the request result as an index will be triggered
    })
  }
}

export function updateQuickLink(quickLink: QuickLink) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    // Map the tags to ensure the values
    const quickLinkToUpdate = {
      ...quickLink,
      tags: (quickLink.tags ?? []).map((tag: any) => {
        if (tag instanceof Object) {
          return tag.value
        } else if (Number(tag)) {
          return Number(tag)
        }
        return tag
      }),
    }

    if (quickLink.image) {
      const data = new FormData()
      data.append('_method', 'PUT')
      data.append('name', quickLink.name)
      data.append('url', quickLink.url)
      data.append('image', quickLink.image as RcFile)

      quickLink.tags.forEach((tag) => data.append('tags[]', tag.toString()))

      if (quickLink.filesToDelete) {
        quickLink.filesToDelete.forEach((fileToDelete) =>
          data.append('filesToDelete[]', fileToDelete.toString())
        )
      }

      return await axios
        .post(
          `${QUICK_LINKS_URL}/${
            quickLink.sourceId ? quickLink.sourceId : quickLink.id
          }`,
          data
        )
        .then((response) => {
          dispatch(getTags())
          //Do not proceed the request result as an index will be triggered
        })
    }

    await axios
      .put(
        `${QUICK_LINKS_URL}/${
          quickLink.sourceId ? quickLink.sourceId : quickLink.id
        }`,
        quickLinkToUpdate
      )
      .then((response) => {
        dispatch(slice.actions.updateQuickLinksuccess(quickLink))
      })
  }
}

export function reorderQuickLinks(newOrder: any[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .post(`${QUICK_LINKS_URL}/reorder`, newOrder)
      .then((response) => {
        dispatch(slice.actions.stopLoading())
      })
  }
}

export function deleteQuickLink(quickLink: QuickLink) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .delete(
        `${QUICK_LINKS_URL}/${
          quickLink.sourceId ? quickLink.sourceId : quickLink.id
        }`
      )
      .then((_response) =>
        dispatch(slice.actions.deleteQuickLinksuccess(quickLink))
      )
  }
}

// ----------------------------------------------------------------------

export function swapQuickLinkOrder(a: QuickLink, b: QuickLink) {
  return async (dispatch: AppDispatch) => {
    const newA: QuickLink = {
      ...a,
      order: b.order,
    }
    const newB: QuickLink = {
      ...b,
      order: a.order,
    }

    dispatch(slice.actions.updateQuickLinksuccess(newA))
    dispatch(slice.actions.updateQuickLinksuccess(newB))
  }
}

/**
 * Selectors
 */
const selectRawItems: SBSelectRaw<{ [key: string]: QuickLink }> = (
  state: RootState
) => state[slice.name].quickLinks
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedQuickLinkId

export const selectQuickLinks = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectSelectedQuickLink = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectQuickLinkById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectQuickLinksByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )
export const selectAllQuickLinks = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )
