import { useEffect, useState } from 'react'
import { useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../authConfig'
import { useNavigate } from 'react-router-dom'
import { Space, Typography } from 'antd'
import { useAppDispatch } from '../reducers/hooks'
import { loginUser } from '../reducers/UserReducer'
import { GraphData } from '../models/GraphData'
import { callMsGraph } from '../utils/MsGraphApiCall'
const { Title, Text } = Typography

const LoginRedirect = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [graphData, setGraphData] = useState<GraphData | undefined>(undefined)

  const { result, error } = useMsalAuthentication(
    InteractionType.Popup,
    loginRequest
  )

  useEffect(() => {
    if (!!graphData) {
      // We already have the data, no need to call the API
      dispatch(
        loginUser({
          id: graphData.id,
          firstName: graphData.givenName,
          lastName: graphData.surname,
          email: graphData.mail,
        })
      )
      navigate('/', { replace: true })
      return
    }

    if (!!error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      return
    }

    if (result) {
      callMsGraph().then((response) => setGraphData(response))
    }
  }, [error, result, graphData])

  return (
    <Space
      direction="vertical"
      style={{ padding: 20, textAlign: 'center' }}
      size={20}
    >
      <img src="assets/linear_logo.png" width={300} />
      <Title style={{ margin: 0 }}>Hi {result?.account?.name}</Title>
      <Text>We need a few seconds to log you in ...</Text>
    </Space>
  )
}

export default LoginRedirect
