import { Picture } from './Picture'
import { Video } from './Video'

export interface Post {
  id: number
  author: number
  status: number
  isLiked: boolean
  isPinned: boolean
  likes: number
  createdAt: string
  medias?: Picture[] | Video[]
  title: string
  content: string
  type: PostType
  tags: number[]
}

export interface PostToUpload {
  id: number
  author: number
  status: number
  isLiked: boolean
  isPinned: boolean
  likes: number
  createdAt: string
  medias?: any[]
  title: string
  content: string
  type: PostType
  tags: number[]
  filesToDelete?: number[]
}

export type PostType = 'default' | 'video' | 'quote'

export const postTypes = [
  { label: 'Default', value: 'default' },
  { label: 'Video', value: 'video' },
  { label: 'Quote', value: 'quote' },
]
