import { useState } from 'react'
import { Button, Form, Input, Space } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'
import { createAuthor } from '../../../reducers/AuthorReducer'
import { Author } from '../../../models/Author'
import { useIntl } from 'react-intl'
import { createAdmin } from '../../../reducers/UserReducer'
import { User } from '../../../models/User'

interface Props {
  onFinish: () => void
}

const Create = ({ onFinish }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [form] = Form.useForm()

  const [formData, setFormData] = useState<User | undefined>(undefined)

  const onBack = () => {
    form.resetFields()
    onFinish()
  }

  const onSubmit = () => {
    if (formData === undefined) {
      return
    }

    dispatch(createAdmin(formData))
    form.resetFields()
    onFinish()
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(_, allFields) => {
        setFormData(allFields)
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="email"
        label={intl.formatMessage({ id: 'EMAIL' })}
        rules={[
          {
            type: 'email',
            message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_EMAIL' }),
          },
          {
            required: true,
            message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: 'SUBMIT' })}
          </Button>
          <Button onClick={onBack}>
            {intl.formatMessage({ id: 'CANCEL' })}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default Create
