import { useSelector } from 'react-redux'
import { selectTagById } from '../../reducers/TagReducer'

type TagProps = {
  id: number
}

const Tag = ({ id }: TagProps) => {
  const tag = useSelector(selectTagById(id))

  return tag ? <>{tag.name}</> : <></>
}

export default Tag
