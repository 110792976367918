import { Modal } from 'antd'
import { useEffect } from 'react'
import { useAppDispatch } from '../../reducers/hooks'
import { getAdmins } from '../../reducers/UserReducer'
import AdminManager from './AdminManager'

interface Props {
  show?: boolean
  onFinish: () => void
}

const AdminModal = ({ show, onFinish }: Props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!show) {
      return
    }
    dispatch(getAdmins())
  }, [show])

  return (
    <Modal open={show} onCancel={onFinish} footer={[]}>
      <AdminManager />
    </Modal>
  )
}

export default AdminModal
