import { Avatar, Button, Dropdown, MenuProps, Space, Typography } from 'antd'
import { useAppDispatch } from '../../reducers/hooks'
import { onOpenCreateModal } from '../../reducers/PostReducer'
import React, { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { BrowserUtils } from '@azure/msal-browser'
import AuthorModal from '../author/AuthorModal'
import { useSelector } from 'react-redux'
import Restricted from '../restricted/Restricted'
import CarouselModal from '../carousel/CarouselModal'
import { selectUser } from '../../reducers/UserReducer'
import LangSelector from '../lang-selector/LangSelector'
import { useIntl } from 'react-intl'
import AdminModal from '../admin/AdminModal'

const Text = Typography

const RightHeader = () => {
  //   const navigate = useNavigate()
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const { instance } = useMsal()

  const [showAuthorManager, setShowAuthorManager] = useState<boolean>(false)
  const [showCarouselManager, setShowCarouselManager] = useState<boolean>(false)
  const [showAdminManager, setShowAdminManager] = useState<boolean>(false)

  const user = useSelector(selectUser)

  const items: MenuProps['items'] = [
    // {
    //   key: 1,
    //   label: <Text>Account</Text>,
    //   onClick: () => navigate('/profile'),
    // },
    {
      key: 2,
      label: <Text>{intl.formatMessage({ id: 'LOGOUT' })}</Text>,
      onClick: () =>
        instance.logoutRedirect({
          account: instance.getActiveAccount(),
          onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        }),
    },
  ]

  return (
    <Space>
      <Restricted type="admin">
        <Space>
          <Button onClick={() => setShowCarouselManager(true)}>
            {intl.formatMessage({ id: 'CAROUSEL.OPEN_BUTTON' })}
          </Button>
          <CarouselModal
            show={showCarouselManager}
            onFinish={() => setShowCarouselManager(false)}
          />
          <Button onClick={() => setShowAuthorManager(true)}>
            {intl.formatMessage({ id: 'AUTHOR.OPEN_BUTTON' })}
          </Button>
          <AuthorModal
            show={showAuthorManager}
            onFinish={() => setShowAuthorManager(false)}
          />

          <Button onClick={() => setShowAdminManager(true)}>
            {intl.formatMessage({ id: 'ADMIN.OPEN_BUTTON' })}
          </Button>
          <AdminModal
            show={showAdminManager}
            onFinish={() => setShowAdminManager(false)}
          />
          <Button onClick={() => dispatch(onOpenCreateModal())}>
            {intl.formatMessage({ id: 'POST.ADD_POST_BUTTON' })}
          </Button>
        </Space>
      </Restricted>
      <LangSelector></LangSelector>
      <Dropdown menu={{ items }} placement="bottomLeft" arrow>
        <Avatar
          style={{
            color: '#fff',
            backgroundColor: '#293348',
            cursor: 'pointer',
          }}
        >
          {user && user.firstName && user.lastName
            ? user!.firstName.slice(0, 1) + user!.lastName.slice(0, 1)
            : '--'}
        </Avatar>
      </Dropdown>
    </Space>
  )
}

export default RightHeader
