import { List, Skeleton, Space } from 'antd'

interface Props {
  isCollapsed: Boolean
}

const LinkListSkeleton = ({ isCollapsed }: Props) => {
  return (
    <List
      itemLayout="vertical"
      dataSource={['', '', '', '', '', '', '', '', '', '', '', '', '', '', '']}
      bordered={false}
      renderItem={(item) =>
        isCollapsed ? (
          <List.Item style={{ border: 'none', margin: '10px 0px' }}>
            <Space direction="horizontal" style={{ width: '100%' }}>
              <Skeleton.Avatar active={true} size={'large'} shape={'circle'} />
            </Space>
          </List.Item>
        ) : (
          <List.Item style={{ border: 'none', margin: '10px 0px' }}>
            <Space direction="horizontal" style={{ width: '100%' }}>
              <Skeleton.Avatar active={true} size={'large'} shape={'circle'} />
              <Space direction="vertical" style={{ width: '100%' }}>
                <Skeleton.Input active={true} size={'default'} block={true} />
              </Space>
            </Space>
          </List.Item>
        )
      }
    />
  )
}

export default LinkListSkeleton
