import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import TextEditor from '../../utils/TextEditor'
import { postTypes } from '../../../models/Post'
import { PlusOutlined } from '@ant-design/icons'
import { useAppDispatch } from '../../../reducers/hooks'
import { Button, Form, Input, Radio, Select, Upload } from 'antd'
import { selectTagsFormFormatted } from '../../../reducers/TagReducer'
import { selectSelectedPost, updatePost } from '../../../reducers/PostReducer'
import { requiredRule, stringRule, youtubeLinkRule } from '../../../utils/rules'
import {
  getAuthors,
  selectAuthorFormFormatted,
} from '../../../reducers/AuthorReducer'
import { asyncCompress } from '../../../utils/ImageCompressor'
import { UploadFile } from 'antd/lib/upload/interface'
import { Video } from '../../../models/Video'
import { Picture } from '../../../models/Picture'
import ProtectedImage from '../../utils/ProtectedImage'

interface Props {
  onFinish: () => void
}

const Update = ({ onFinish }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const selectedPost = useSelector(selectSelectedPost())
  const tagOptions = useSelector(selectTagsFormFormatted())
  const authorOptions = useSelector(selectAuthorFormFormatted())

  const [idsToDelete, setIdsToDelete] = useState<string[]>([])

  const onSubmit = async () => {
    const compressedFiles = await Promise.all(
      (form.getFieldValue('medias').fileList ?? [])
        .filter((f: UploadFile<any>) => f.uid.startsWith('rc-upload-'))
        .flatMap(async (file: UploadFile<any>) => {
          const compressed = await asyncCompress(file.originFileObj!)
          return compressed
        })
    )
    const postToUpdate = {
      ...form.getFieldsValue(),
      medias: compressedFiles,
      filesToDelete: Object.values(form.getFieldValue('filesToDelete') ?? {}),
    }

    dispatch(updatePost(postToUpdate))
    setIdsToDelete([])
    form.resetFields()
    onFinish()
  }

  useEffect(() => {
    dispatch(getAuthors())

    if (!selectedPost) {
      return
    }

    setIdsToDelete([])
    form.setFieldsValue(selectedPost)
    form.setFieldValue(
      'tags',
      selectedPost.tags.map((t) => String(t))
    )
    form.setFieldValue('author', String(selectedPost.author))

    if (selectedPost?.type === 'video') {
      form.setFieldValue(
        ['media', 0, 'src'],
        (selectedPost.medias![0] as Video).src
      )
    }
  }, [form, selectedPost])

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={selectedPost}
      onFinish={onSubmit}
    >
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="status"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>

      <Form.Item
        name="type"
        label={intl.formatMessage({ id: 'TYPE' })}
        rules={[requiredRule(intl)]}
      >
        <Radio.Group disabled options={postTypes} optionType="button" />
      </Form.Item>
      <Form.Item
        name="title"
        label={intl.formatMessage({ id: 'TITLE' })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="content"
        label={intl.formatMessage({ id: 'BODY' })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        {/* @ts-ignore */}
        <TextEditor />
      </Form.Item>
      <Form.Item
        name="tags"
        label={intl.formatMessage({ id: 'TAGS' })}
        rules={[requiredRule(intl)]}
      >
        <Select mode="tags" style={{ width: '100%' }} options={tagOptions} />
      </Form.Item>
      <Form.Item
        name="author"
        label={intl.formatMessage({ id: 'AUTHOR' })}
        rules={[requiredRule(intl)]}
      >
        <Select style={{ width: '100%' }} options={authorOptions} />
      </Form.Item>

      {selectedPost?.type === 'video' && (
        <Form.Item
          name={['media', 0, 'src']}
          label={intl.formatMessage({ id: 'VIDEO_LINK' })}
          rules={[requiredRule(intl), youtubeLinkRule(intl)]}
        >
          <Input />
        </Form.Item>
      )}
      {selectedPost?.type === 'default' && (
        <>
          <Form.Item
            name="medias"
            label={intl.formatMessage({ id: 'FIELD_LABEL.FILES' })}
          >
            <Upload
              multiple
              accept="image/*"
              listType="picture-card"
              beforeUpload={() => {
                return false
              }}
              onRemove={(file) => {
                if (!file.uid.startsWith('rc-upload-')) {
                  setIdsToDelete([...idsToDelete, file.uid])
                }
              }}
              defaultFileList={((selectedPost.medias ?? []) as Picture[]).map(
                (file: Picture) => ({
                  uid: `${file.id}`,
                  name: file.name,
                  status: 'done',
                  url: file.src,
                })
              )}
              itemRender={(originNode, file, fileList, actions) => {
                if (file.uid.startsWith('rc-upload-')) {
                  return originNode
                }

                return (
                  <>
                    <div
                      className="grid-image"
                      style={{
                        width: '100px',
                        height: '100px',
                        position: 'absolute',
                      }}
                    >
                      <ProtectedImage
                        picture={{ src: file.url } as Picture}
                      ></ProtectedImage>
                      <Button onClick={actions.remove}>Delete</Button>
                    </div>
                  </>
                )
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>
                  {intl.formatMessage({ id: 'SELECT_FILES' })}
                </div>
              </div>
            </Upload>
          </Form.Item>
          {idsToDelete.map((id) => (
            <Form.Item
              key={id}
              initialValue={id}
              style={{ display: 'none' }}
              name={['filesToDelete', id]}
            >
              <Input type="hidden" value={id} />
            </Form.Item>
          ))}
        </>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {intl.formatMessage({ id: 'POST.UPDATE_POST_BUTTON' })}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Update
