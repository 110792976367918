import { Rule } from 'antd/es/form'
import { IntlShape } from 'react-intl'
import dayjs, { Dayjs } from 'dayjs'

export const integerRule: Rule = {
  type: 'integer',
  message: 'The input is not valid number',
}

export const minLengthRule = function (length: number, intl: IntlShape): Rule {
  return {
    pattern: new RegExp(`.{${length.toString()},}`, 'g'),
    message: intl.formatMessage(
      { id: 'FIELD.ERROR.MIN_LENGTH' },
      { length: length }
    ),
  }
}

export const capsRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/[A-Z]+/g),
    message: intl.formatMessage({ id: 'FIELD.ERROR.CAPS' }),
  }
}
export const lowercaseRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/[a-z]+/g),
    message: intl.formatMessage({ id: 'FIELD.ERROR.LOWERCASE' }),
  }
}

export const containsNumberRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/\d+/g),
    message: intl.formatMessage({ id: 'FIELD.ERROR.CONTAINS_NUMBER' }),
  }
}
export const containsSymbolRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/\W+/g),
    message: intl.formatMessage({ id: 'FIELD.ERROR.CONTAINS_SYMBOL' }),
  }
}

export const numberRule = function (intl: IntlShape): Rule {
  return {
    type: 'number',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_NUMBER' }),
  }
}

export const stringRule = function (intl: IntlShape): Rule {
  return {
    type: 'string',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_STRING' }),
  }
}

export const urlRule = function (intl: IntlShape): Rule {
  return {
    type: 'url',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_URL' }),
  }
}

export const emailRule = function (intl: IntlShape): Rule {
  return {
    type: 'email',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_EMAIL' }),
  }
}

export const requiredRule = function (intl: IntlShape): Rule {
  return {
    required: true,
    message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
  }
}

export const acceptedRule = function (intl: IntlShape): Rule {
  return {
    validator(_, value) {
      if (value) {
        return Promise.resolve()
      }
      return Promise.reject(intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }))
    },
  }
}

export const minimumFilesCountRule = function (
  intl: IntlShape,
  minimum: number = 1
): Rule {
  return {
    validator(_, value) {
      if (Array.isArray(value) && value.length >= minimum) {
        return Promise.resolve()
      } else if (value && value.fileList && value.fileList.length >= minimum) {
        return Promise.resolve()
      }
      return Promise.reject(
        intl.formatMessage({ id: 'FIELD.ERROR.AT_LEAST_ONE_FILE' })
      )
    },
  }
}

export const youtubeLinkRule = function (intl: IntlShape): Rule {
  return {
    pattern: RegExp('^(https?://)?((www.)?youtube.com|youtu.be)/.+$'),
    message: intl.formatMessage({
      id: 'FIELD.ERROR.TYPE_YOUTUBE_LINK',
    }),
  }
}
