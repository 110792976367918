import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { Post } from '../models/Post'
import { PostToUpload } from '../models/Post'
import { stringifyRequestQuery } from '../utils/crud-helper/helpers'
import { initialQueryState, QueryState } from '../utils/crud-helper/models'
import { POSTS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { getTags } from './TagReducer'
import { RcFile } from 'antd/es/upload'

// utils
// @types

// ----------------------------------------------------------------------
export type PostState = {
  isLoading: boolean
  error: Error | string | null
  posts: Post[]
  totalPosts: number
  selectedPostId: number | undefined
  showCreateModal: boolean
  showEditModal: boolean
  query: QueryState
}

const initialState: PostState = {
  isLoading: false,
  error: null,
  posts: [],
  totalPosts: 0,
  selectedPostId: undefined,
  showCreateModal: false,
  showEditModal: false,
  query: initialQueryState,
}

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // HAS ERROR
    nextPostsPage(state) {
      state.query.page++
    },

    // GET Posts
    getPostsSuccess(state, action) {
      state.isLoading = false
      state.posts = [...state.posts, ...action.payload.data]
      state.totalPosts = action.payload.totalRecords
    },

    // RESET Posts
    resetPostsQuery(state) {
      state.query = initialQueryState
      state.posts = []
      state.totalPosts = 0
    },

    // CREATE EVENT
    createPostsuccess(state, action) {
      const newPost = action.payload
      state.isLoading = false
      state.posts = [newPost, ...state.posts]
    },

    // UPDATE EVENT
    updatePostsuccess(state, action) {
      state.isLoading = false
      state.posts = state.posts.map((post) => {
        if (post.id === action.payload.id) {
          state.showEditModal = false
          return action.payload
        }
        return post
      })
    },

    // DELETE EVENT
    deletePostsuccess(state, action) {
      const postId = action.payload
      state.posts = state.posts.filter((post) => post.id !== postId)
      state.totalPosts--
    },

    // SELECT EVENT
    selectPostId(state, action) {
      state.showEditModal = true
      const postId = action.payload
      state.selectedPostId = postId
    },

    onOpenCreateModal(state) {
      state.showCreateModal = true
    },

    onOpenUpdateModal(state) {
      state.showEditModal = true
    },

    onCloseCreateModal(state) {
      state.showCreateModal = false
      state.selectedPostId = undefined
    },

    onCloseUpdateModal(state) {
      state.showEditModal = false
      state.selectedPostId = undefined
    },
  },
})

// Reducer
export default slice.reducer

export const {
  selectPostId,
  onOpenCreateModal,
  onOpenUpdateModal,
  onCloseCreateModal,
  onCloseUpdateModal,
  nextPostsPage,
  resetPostsQuery,
} = slice.actions

export function getPosts(query: QueryState) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .get(`${POSTS_URL}?${stringifyRequestQuery(query)}`)
      .then((response) => {
        const posts = response.data
        dispatch(slice.actions.getPostsSuccess(posts))
      })
  }
}

export function createPost(newPost: PostToUpload) {
  //In case the post is type = 'default' and medias is filled we need to send the data as FormData, cause of the images

  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    if (newPost.type === 'default' && newPost.medias) {
      const postToSubmit = new FormData()
      postToSubmit.append('title', newPost.title)
      postToSubmit.append('content', newPost.content)
      postToSubmit.append('author', newPost.author.toString())
      postToSubmit.append('type', newPost.type)

      newPost.tags.forEach((tag) =>
        postToSubmit.append('tags[]', tag.toString())
      )

      newPost.medias.forEach((media) =>
        postToSubmit.append('medias[]', media as RcFile)
      )

      await axios.post(POSTS_URL, postToSubmit).then(() => {
        dispatch(resetPostsQuery())
        dispatch(getTags())
      })

      return
    }

    await axios.post(POSTS_URL, newPost).then(() => {
      dispatch(resetPostsQuery())
      dispatch(getTags())
    })
  }
}

export function updatePost(post: PostToUpload) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    if (post.type === 'default' && post.medias) {
      const postToSubmit = new FormData()
      postToSubmit.append('_method', 'PUT')
      postToSubmit.append('title', post.title)
      postToSubmit.append('content', post.content)
      postToSubmit.append('status', post.status.toString())
      postToSubmit.append('author', post.author.toString())
      postToSubmit.append('type', post.type)

      post.tags.forEach((tag) => postToSubmit.append('tags[]', tag.toString()))

      post.medias.forEach((media) =>
        postToSubmit.append('medias[]', media as RcFile)
      )

      if (post.filesToDelete) {
        post.filesToDelete.forEach((fileToDelete) =>
          postToSubmit.append('filesToDelete[]', fileToDelete.toString())
        )
      }

      await axios
        .post(`${POSTS_URL}/${post.id}`, postToSubmit)
        .then((response) => {
          const postUpdated = response.data.data
          dispatch(getTags()) // Fetch tags in case a new tag was created
          dispatch(slice.actions.updatePostsuccess(postUpdated))
        })

      return
    }

    await axios.put(`${POSTS_URL}/${post.id}`, post).then((response) => {
      const postUpdated = response.data.data
      dispatch(getTags()) // Fetch tags in case a new tag was created
      dispatch(slice.actions.updatePostsuccess(postUpdated))
    })
  }
}

// ----------------------------------------------------------------------

export function deletePost(post: Post) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.deletePostsuccess(post.id))
    await axios
      .delete(`${POSTS_URL}/${post.id}`)
      .then((_response) => dispatch(slice.actions.stopLoading()))
  }
}

// ----------------------------------------------------------------------

export function updatePostStatus(post: Post, statusId: Number) {
  const postToUpdate = {
    ...post,
    status: statusId,
  }
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.updatePostsuccess(postToUpdate))
    await axios.put(`${POSTS_URL}/${post.id}/status`, { status: statusId })
  }
}

export function togglePostPin(post: Post) {
  const postToUpdate: Post = {
    ...post,
    isPinned: !post.isPinned,
  }
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.updatePostsuccess(postToUpdate))
    await axios
      .put(`${POSTS_URL}/${post.id}`, postToUpdate)
      .then((_response) => dispatch(resetPostsQuery()))
  }
}

export function togglePostLike(post: Post) {
  const postToUpdate = {
    ...post,
    likes: post.likes + (post.isLiked ? -1 : 1),
    isLiked: !post.isLiked,
  }
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.updatePostsuccess(postToUpdate))
    await axios.post(`${POSTS_URL}/${post.id}/like`, postToUpdate)
  }
}

/**
 * Selectors
 */
export const selectPosts = (state: RootState): Post[] => state.posts.posts

export const selectQuery = (state: RootState): QueryState => state.posts.query

export const selectPostQuery = () =>
  createSelector<any, QueryState>([selectQuery], (query: QueryState) => query)

export const selectSelectedPostId = (state: RootState): number | undefined =>
  state.posts.selectedPostId

export const selectPostById = (id: number) =>
  createSelector<any, Post | undefined>([selectPosts], (posts: Post[]) =>
    (posts ?? []).find((p) => p.id === id)
  )

export const selectSelectedPost = () =>
  createSelector<any, Post | undefined>(
    [selectPosts, selectSelectedPostId],
    (posts: Post[], selectedPostId: number | undefined) =>
      (posts ?? []).find((p) => p.id === selectedPostId)
  )
