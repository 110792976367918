import React, { useState } from 'react'
import {
  Alert,
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Upload,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { createQuickLink } from '../../../reducers/QuickLinksReducer'
import { useSelector } from 'react-redux'
import { selectTagsFormFormatted } from '../../../reducers/TagReducer'
import { useIntl } from 'react-intl'
import { requiredRule, stringRule, urlRule } from '../../../utils/rules'
import { asyncCompress } from '../../../utils/ImageCompressor'
import ImageFromLink from '../components/ImageFromLink'

interface Props {
  onFinish: () => void
}

const Create = ({ onFinish }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const [isImageFromLink, setIsImageFromLink] = useState<boolean>(true)

  const tagOptions = useSelector(selectTagsFormFormatted())
  const { isLoading } = useAppSelector((state) => state.quickLink)

  const onSubmit = async () => {
    const compressedImage =
      form.getFieldValue('image_method') === 'file'
        ? await asyncCompress(
            form.getFieldValue('image').fileList[0].originFileObj
          )
        : undefined
    dispatch(
      createQuickLink({ ...form.getFieldsValue(), image: compressedImage })
    ).then(() => {
      form.resetFields()
      onFinish()
    })
  }

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: 'NAME' })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        label={intl.formatMessage({ id: 'LINK' })}
        rules={[requiredRule(intl), urlRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="tags"
        label={intl.formatMessage({ id: 'TAGS' })}
        rules={[requiredRule(intl)]}
      >
        <Select mode="tags" style={{ width: '100%' }} options={tagOptions} />
      </Form.Item>

      <Form.Item
        name="image_method"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: 'QUICKLINK.IMAGE_METHOD' })}
      >
        <Radio.Group
          optionType="button"
          defaultValue={'link'}
          options={[
            { label: 'From link', value: 'link' },
            { label: 'From file', value: 'file' },
          ]}
          onChange={(e: RadioChangeEvent) =>
            setIsImageFromLink(e.target.value === 'link')
          }
        />
      </Form.Item>
      {isImageFromLink && <ImageFromLink />}
      {!isImageFromLink && (
        <Form.Item
          name="image"
          rules={[requiredRule(intl)]}
          label={intl.formatMessage({ id: 'FIELD_LABEL.FILES' })}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => {
              return false
            }}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>
                {intl.formatMessage({ id: 'SELECT_FILES' })}
              </div>
            </div>
          </Upload>
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {intl.formatMessage({ id: 'SUBMIT' })}
          </Button>
          <Button onClick={onFinish} disabled={isLoading}>
            {intl.formatMessage({ id: 'CANCEL' })}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default Create
