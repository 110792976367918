import PostList from '../components/post/PostList'
import CarouselWrapper from '../components/carousel/CarouselWrapper'
import { Col, Row } from 'antd'

const Home = () => {
  return (
    <Row style={{ maxWidth: 900, margin: 'auto' }}>
      <Col md={24}>
        <CarouselWrapper />
        <PostList />
      </Col>
    </Row>
  )
}

export default Home
