import { Modal } from 'antd'
import { useAppDispatch } from '../../reducers/hooks'
import {
  onCloseCreateAuthorModal,
  onCloseUpdateAuthorModal,
} from '../../reducers/AuthorReducer'
import AuthorManager from './CarouselManager'
import CarouselManager from './CarouselManager'

interface Props {
  show?: boolean
  onFinish: () => void
}

const CarouselModal = ({ show, onFinish }: Props) => {
  const dispatch = useAppDispatch()

  return (
    <>
      <Modal open={show} onCancel={onFinish} footer={[]}>
        <CarouselManager />
      </Modal>
    </>
  )
}

export default CarouselModal
