import axios from 'axios'
import { AppDispatch, RootState } from './store'
import { Author } from '../models/Author'
import { CAROUSEL_SLIDE_URL, CAROUSEL_URL } from '../utils/urls'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { CarouselConfig } from '../models/CarouselConfig'
import {
  SlideModel,
  SlideModelCreation,
  SlideModelUpdate,
} from '../models/Slide'
import { RcFile } from 'antd/es/upload'

// utils
// @types

// ----------------------------------------------------------------------
export type CarouselState = {
  isLoading: boolean
  error: Error | string | null
  slides: SlideModel[]
  config: CarouselConfig
  selectedSlideId: number | undefined
}

const initialState: CarouselState = {
  isLoading: false,
  error: null,
  slides: [],
  config: {
    duration: 5000,
    autoplay: true,
    hide: true,
  },
  selectedSlideId: undefined,
}

const slice = createSlice({
  name: 'carousel',
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Authors
    getCarouselSuccess(state, action) {
      state.config = action.payload
      state.slides = action.payload.slides
    },

    // CREATE EVENT
    createSlideSuccess(state, action) {
      const newSlide = action.payload
      state.slides = [...state.slides, newSlide]
    },

    // UPDATE EVENT
    updateSlidesuccess(state, action) {
      state.slides = state.slides.map((slide) => {
        if (slide.id === action.payload.id) {
          return action.payload
        }
        return slide
      })
    },

    selectSlideId(state, action) {
      const slideId = action.payload
      state.selectedSlideId = slideId
    },

    // UPDATE EVENT
    upSlidesuccess(state, action) {
      const firstInx = state.slides.map((e) => e.id).indexOf(action.payload.id)
      const secInx = firstInx - 1
      const results = state.slides.slice()
      const firstItem = state.slides[firstInx]
      results[firstInx] = state.slides[secInx]
      results[secInx] = firstItem
      state.slides = results
    },

    downSlideSuccess(state, action) {
      const firstInx = state.slides.map((e) => e.id).indexOf(action.payload.id)
      const secInx = firstInx + 1
      const results = state.slides.slice()
      const firstItem = state.slides[firstInx]
      results[firstInx] = state.slides[secInx]
      results[secInx] = firstItem
      state.slides = results
    },

    // DELETE EVENT
    deleteSlideSuccess(state, action) {
      const slideId = action.payload
      state.slides = state.slides.filter((slide) => slide.id !== slideId)
    },

    // UPDATE EVENT
    updateConfigSuccess(state, action) {
      state.config = action.payload
    },
  },
})

export const { selectSlideId } = slice.actions

export default slice.reducer

export function getCarousel() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(CAROUSEL_URL).then((response) => {
      const carousel = response.data.data
      dispatch(slice.actions.getCarouselSuccess(carousel))
      dispatch(slice.actions.stopLoading())
    })
  }
}

export function updateConfig(config: CarouselConfig) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.updateConfigSuccess(config))
    await axios.put(CAROUSEL_URL, config).then((_response) => {
      dispatch(slice.actions.stopLoading())
    })
  }
}

export function createSlide(slide: SlideModelCreation) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    const slideToSubmit = new FormData()
    slideToSubmit.append('title', slide.title)
    slideToSubmit.append('body', slide.body)
    slideToSubmit.append('link', slide.link)
    slideToSubmit.append('picture', slide.picture as RcFile)

    await axios.post(CAROUSEL_SLIDE_URL, slideToSubmit).then((response) => {
      const slideCreated = response.data.data
      dispatch(slice.actions.createSlideSuccess(slideCreated))
      dispatch(slice.actions.stopLoading())
    })
  }
}

export function updateSlide(slide: SlideModelUpdate) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    const slideToSubmit = new FormData()
    slideToSubmit.append('title', slide.title)
    slideToSubmit.append('body', slide.body)
    slideToSubmit.append('link', slide.link)
    if (slide.picture !== undefined) {
      slideToSubmit.append('picture', slide.picture as RcFile)
    }

    await axios
      .post(`${CAROUSEL_SLIDE_URL}/${slide.id}`, slideToSubmit)
      .then((response) => {
        dispatch(slice.actions.updateSlidesuccess(response.data.data))
        dispatch(slice.actions.stopLoading())
      })
  }
}

export function upSlide(slide: SlideModel) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.upSlidesuccess(slide))
    await axios
      .post(`${CAROUSEL_SLIDE_URL}/${slide.id}/up`)
      .then((_response) => {
        dispatch(slice.actions.stopLoading())
      })
  }
}

export function downSlide(slide: SlideModel) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.downSlideSuccess(slide))
    await axios
      .post(`${CAROUSEL_SLIDE_URL}/${slide.id}/down`)
      .then((_response) => {
        dispatch(slice.actions.stopLoading())
      })
  }
}

// ----------------------------------------------------------------------

export function deleteSlide(slide: SlideModel) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.deleteSlideSuccess(slide.id))
    await axios
      .delete(`${CAROUSEL_SLIDE_URL}/${slide.id}`)
      .then((_response) => {
        dispatch(slice.actions.stopLoading())
      })
  }
}

/**
 * Selectors
 */
export const selectSlides = (state: RootState): SlideModel[] => {
  return state.carousel.slides
}

export const selectConfig = (state: RootState): CarouselConfig =>
  state.carousel.config

export const selectSelectedSlideId = (state: RootState): number | undefined =>
  state.carousel.selectedSlideId

export const selectSelectedSlide = () =>
  createSelector<any, SlideModel | undefined>(
    [selectSlides, selectSelectedSlideId],
    (slides: SlideModel[], selectedSlideId: number | undefined) =>
      (slides ?? []).find((q) => q.id === selectedSlideId)
  )
