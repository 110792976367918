import { Avatar } from 'antd'
import { useSelector } from 'react-redux'
import { selectAuthorById } from '../../reducers/AuthorReducer'

interface Props {
  authorId: number
}

const AuthorAvatar = ({ authorId }: Props) => {
  const author = useSelector(selectAuthorById(authorId))

  return <>{author && <Avatar src={author.avatarUrl} size={200} />}</>
}

export default AuthorAvatar
