import { useEffect, useState } from 'react'
import { Button, Form, Input, Radio, Select, Space, Upload } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { useAppDispatch } from '../../../reducers/hooks'
import { Post as PostModel, postTypes } from '../../../models/Post'
import { PostToUpload } from '../../../models/Post'
import { createPost } from '../../../reducers/PostReducer'
import {
  getAuthors,
  selectAuthorFormFormatted,
} from '../../../reducers/AuthorReducer'
import { useSelector } from 'react-redux'
import { selectTagsFormFormatted } from '../../../reducers/TagReducer'
import { useIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons'
import { asyncCompress } from '../../../utils/ImageCompressor'
import TextEditor from '../../utils/TextEditor'
import { requiredRule, stringRule, youtubeLinkRule } from '../../../utils/rules'

interface Props {
  onFinish: () => void
}

const Create = ({ onFinish }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const tagOptions = useSelector(selectTagsFormFormatted())
  const authorOptions = useSelector(selectAuthorFormFormatted())

  const [formData, setFormData] = useState<PostModel | undefined>(undefined)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const onSubmit = async () => {
    if (formData === undefined) {
      return
    }

    var postToUpload: PostToUpload
    switch (formData.type) {
      case 'default':
        const compressedFiles = await Promise.all(
          fileList.flatMap(async (file) => {
            const compressed = await asyncCompress(file.originFileObj!)
            return compressed
          })
        )

        postToUpload = { ...formData, medias: compressedFiles }
        break

      default:
        postToUpload = formData
        break
    }

    dispatch(createPost(postToUpload))
    form.resetFields()
    setFileList([])
    onFinish()
  }

  useEffect(() => {
    dispatch(getAuthors())
  }, [dispatch])

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(_, allFields) => {
        setFormData(allFields)
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="type"
        required
        label={intl.formatMessage({ id: 'TYPE' })}
        rules={[requiredRule(intl)]}
      >
        <Radio.Group options={postTypes} optionType="button" />
      </Form.Item>
      <Form.Item
        name="title"
        required
        label={intl.formatMessage({ id: 'TITLE' })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="content"
        required
        label={intl.formatMessage({ id: 'BODY' })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        {/* @ts-ignore */}
        <TextEditor />
      </Form.Item>
      <Form.Item
        name="tags"
        required
        label={intl.formatMessage({ id: 'TAGS' })}
        rules={[requiredRule(intl)]}
      >
        <Select mode="tags" style={{ width: '100%' }} options={tagOptions} />
      </Form.Item>

      <Form.Item
        name="author"
        required
        label={intl.formatMessage({ id: 'AUTHOR' })}
        rules={[requiredRule(intl)]}
      >
        <Select style={{ width: '100%' }} options={authorOptions} />
      </Form.Item>

      <Form.Item
        name="media"
        hidden={!formData || formData.type !== 'default'}
        label={intl.formatMessage({ id: 'FIELD_LABEL.FILES' })}
      >
        <Upload
          multiple
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          onChange={({ fileList: newFileList }) => setFileList(newFileList)}
          beforeUpload={() => {
            return false
          }}
          onRemove={(file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
          }}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>
              {intl.formatMessage({ id: 'SELECT_FILES' })}
            </div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item
        hidden={!formData || formData.type !== 'video'}
        name={['media', 0, 'src']}
        label={intl.formatMessage({ id: 'VIDEO_LINK' })}
        rules={[
          {
            required: formData && formData.type === 'video',
            message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
          },
          youtubeLinkRule(intl),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: 'SUBMIT' })}
          </Button>
          <Button type="default" htmlType="reset" onClick={() => onFinish()}>
            {intl.formatMessage({ id: 'CANCEL' })}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default Create
